/** @jsxImportSource @emotion/react */

import FilterableConfigList from "./FilterableConfigList";
import { MimeType } from "../../../../constants/misc";

const defaultField = {
    field: {
        label: "Image option", value: "image_option",
    }
};
export default function ImageOptionsConfigForm({ name, title, fields, description, setArray, useDisplayFilter = false }) {
    const getFieldTitle = (object) => <span>{object.label} [{object.value}] </span>;
    const getFieldForm = () => [
        {
            label: "Image",
            name: "image_url",
            description: "Upload image to be used as an icon instead of label. Webp format only. https://cloudinary.com/tools/image-to-webp",
            type: "file-select",
            accept: [MimeType.Webp],
            upload_config_image: true
        },
        {
            label: "Value",
            name: "value",
            description: "Value of the option",
            type: "text"
        },
    ];


    return <FilterableConfigList
        useDisplayFilter={useDisplayFilter as false | string[]}
        name={name}
        title={title}
        description={description}
        fields={fields}
        setArray={setArray}
        defaultField={defaultField}
        getFieldForm={getFieldForm}
        getFieldTitle={getFieldTitle}
    />;

}

