import { PATCH as PATCH_USER } from "../Users/actionsTypes";
import userReducer from "../Users/reducer";
import {
    AUTH,
    GET,
    LOGOUT,
    POWERBI_GET,
    POWERBI_LOGOUT,
    UPDATE,
} from "./actionsTypes";

const defaultState = { isLoading: false, user: { tenant: {}, authenticated: false }, backend_version: false };
export default (state = defaultState, action) => {
    switch (action.type) {
    case AUTH.LOADING:
        return { ...state, isLoading: action.data };

    case LOGOUT.SUCCESS:
        return { ...state, authenticated: false, user: { tenant: {} } };

    case UPDATE.SUCCESS:
        return {
            ...state, user: action.payload.data, isLoading: false, authenticated: true, powerbiEmbedInfo: undefined,
        };

    case GET.LOADING:
        return { ...state, isLoading: true };

    case GET.SUCCESS:
        return {
            ...state, user: action.payload.data, isLoading: false, authenticated: true,
        };

    case GET.ERROR:
        return {
            ...state, isLoading: false, authenticated: false, user: { tenant: {} },
        };

    case POWERBI_GET.LOADING:
        return { ...state, isPowerbiLoading: true };

    case POWERBI_GET.SUCCESS:
        return { ...state, powerbiEmbedInfo: action.payload.data, isPowerbiLoading: false };

    case POWERBI_GET.ERROR:
        return { ...state, isPowerbiLoading: false };

    case POWERBI_LOGOUT.SUCCESS:
        return { ...state, powerbiEmbedInfo: undefined };


    case PATCH_USER.LOADING:
    case PATCH_USER.ERROR:
    case PATCH_USER.SUCCESS:
        return {
            ...state,
            user: userReducer(state.user, action),
        };

    default:
        return state;
    }
};
