import { useEffect, useRef, useState } from "react";
import { Input, InputGroup } from "reactstrap";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { MimeType } from "../../constants/misc";
import { FormFileSelectFieldReadFormat } from "../../actions/Tenants/config/constantsTyped";
import useAvosApi from "../../utils/useAvosApiHook";

export interface FileSelectProps {
    onSelect: (fileName: string, fileContent: string) => void;
    onSelectImage: (url: string) => void;
    accept: MimeType[];
    readFormat?: FormFileSelectFieldReadFormat;
    upload_config_image?: boolean;
    fileObject?: any;
}


export function FileSelect({ onSelect, onSelectImage, accept = [], readFormat = FormFileSelectFieldReadFormat.Base64, upload_config_image, fileObject }: FileSelectProps) {
    const fileInputRef = useRef<HTMLInputElement>(null);
    const isValidFileType = (fileType: MimeType, accept: MimeType[]) => {
        return accept.includes(fileType);
    };

    const tenant_id = useSelector((state: any) => state.tenants.current.tenant_id);
    const [uploadedImage, setUploadedImage] = useState<string | null>(null);

    const [UploadImage, blob_image_url, uploadError] = useAvosApi(
        `/tenants/${tenant_id}/configs/upload-image`,
        "POST"
    );

    useEffect(() => {
        if (blob_image_url && typeof blob_image_url === "string") {
            onSelectImage(blob_image_url);
            setUploadedImage(blob_image_url);
        }
    }, [blob_image_url]);

    const uploadImage = async (image_name: string, base64: string) => {
        try {
            if (typeof UploadImage === "function") {
                await UploadImage(
                    {
                        image_name,
                        base64,
                        type: "image",
                    },
                    {
                        endpoint: `/tenants/${tenant_id}/configs/upload-image`,
                        method: "POST",
                    }
                );

            } else {
                throw new Error("API is not available.");
            }
        } catch (error) {
            const errorMessage = uploadError instanceof Error ? uploadError.message : (error as Error).message;
            toast.error(`Failed to upload image: ${errorMessage}`, {
                autoClose: 3000,
            });
        }
    };
    const fileChangeHandler = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (isValidFileType(file.type as MimeType, accept)) {
                const reader = new FileReader();
                reader.onload = async (event) => {
                    try {
                        const content = event?.target?.result as string;

                        if (upload_config_image) {
                            uploadImage(file.name, content);
                        } else {
                            await onSelect(file.name, content);
                        }

                    } catch (error: any) {
                        toast.error(error.message, {
                            autoClose: 3000,
                        });
                    }
                };
                if (readFormat === FormFileSelectFieldReadFormat.Base64) {
                    reader.readAsDataURL(file); // Use readAsDataURL to get base64-encoded string
                } else if (readFormat === FormFileSelectFieldReadFormat.Raw) {
                    reader.readAsArrayBuffer(file); // Use readAsArrayBuffer to get raw binary data
                }
            } else {
                toast.error(`Invalid file type. Please select a file of type ${accept.join(",")}`, {
                    autoClose: 3000,
                });
            }
        }
    };

    useEffect(() => {
        if (fileObject?.image_url === null && fileInputRef.current) {
            fileInputRef.current.value = "";
            setUploadedImage(null);
        }
    }, [fileObject?.image_url]);

    return (
        <InputGroup>
            <div className="file-input-wrapper d-flex align-items-center">
                <Input
                    type="file"
                    accept={accept.join(",")}
                    onChange={fileChangeHandler}
                    className="file-input"
                    innerRef={fileInputRef}
                />
                {(uploadedImage || fileObject?.image_url) && (
                    <img
                        src={uploadedImage || fileObject?.image_url}
                        alt="Selected image"
                        className="file-preview-image ms-3"
                        width="80px"
                        height="80px"
                    />
                )}
            </div>
        </InputGroup>
    );
}
