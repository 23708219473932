/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Col,
    Input,
    InputGroup,
    InputGroupText,
    Label,
    Row
} from "reactstrap";
import { postDefect } from "../../../../actions/Avocados/actions";
import { patchCheck, updateCheckState } from "../../../../actions/Checks/actions";
import { applyTriggerFilterFlags } from "../../../../actions/Tenants/config/applyDisplayFilter";
import useConfig from "../../../../actions/Tenants/config/configHook";
import { find_category } from "../../../../actions/Tenants/config/utils";
import { getFilledArrayOrDefault } from "../../../../utils";
import MetaForm, { metaFormIsValid } from "../../../Forms/MetaForm";
import { TenantDefect, doFilterDefects, useDefectsHook } from "../../../Forms/useManagedOptionsHook";
import { metricPercentage, toPercentage } from "../../Layers/fields/formatters";
import Box from "./Layout";
import CheckImages from "./Summary/CheckImages";
import { ColorCodedSpan } from "./Summary/FruitTable";

const appendend_style = css`width: 4.5rem;`;

export default function VisualCheck() {
    const config = useConfig();
    const isLoading = useSelector((state: any) => state.checks.isLoading);
    const check = useSelector((state: any) => state.checks.current);
    const layer = useSelector((state: any) => state.layers.current);
    const location = config.get_location(layer, check);
    const layer_config = config.get_layer_config(layer);
    const location_config = config.get_location(layer, check);

    const dispatch = useDispatch();

    const first_column_width = getFilledArrayOrDefault(location_config?.visual_check_defect_form, [])[0]?.lg || 6;
    const canSubmit = () => metaFormIsValid(location_config.visual_check_form, check, false);

    return (
        <Box hideButtons={isLoading} canSubmit={canSubmit()}>
            {!isLoading && <>
                <Row>
                    <Col lg={first_column_width}>
                        <MetaForm
                            meta={location_config.visual_check_form}
                            object={check}
                            onDebounce={(field, value) => dispatch(patchCheck(check.test_id, field, value, true) as any)}
                            setValue={(field, value) => dispatch(patchCheck(check.test_id, field, value, false) as any)}
                            config={config}
                        />
                    </Col>
                </Row>
                <OptionsForm />
                <DefectFrequencyForm />
                {check.test_id > 0 && <div>
                    <CheckImages test_id={check.test_id} required_images={location.required_images || layer_config.show_images || []} />
                </div>}
            </>
            }
        </Box>
    );
}


const countDefects = (all_defects, check_defects) => {
    all_defects.reduce((acc, defect) => {
        const key_combined = `${defect.position}_${defect.severity}`;
        const total_combined = (acc[key_combined] || 0) + (check_defects?.[defect.defect_id] || 0); // internal_minor / internal_major / internal_unacceptable / external_minor / external_major / external_unacceptable
        const total_position = (acc[defect.position] || 0) + (check_defects?.[defect.defect_id] || 0); // internal / external
        const total_severity = (acc[defect.severity] || 0) + (check_defects?.[defect.defect_id] || 0); // minor / major / unacceptable
        return { ...acc, [key_combined]: total_combined, [defect.position]: total_position, [defect.severity]: total_severity };
    }, {});

};

export const DefectFrequencyForm = () => {
    const config = useConfig();
    const check = useSelector((state: any) => state.checks.current);
    const layer = useSelector((state: any) => state.layers.current);
    const location_config = config.get_location(layer, check);
    const dispatch = useDispatch();
    const frequency = check.defects as { [key: string]: number };

    const all_defects = useDefectsHook(layer.fruit_type) as TenantDefect[];

    const updateCheckDefectsState = (defect_id, frequency) => {
        const defects = { ...check.defects, [defect_id]: Number(frequency) };
        const total_defects = countDefects(all_defects, defects);
        dispatch(updateCheckState({ ...check, defects, total_defects }));
    };

    const callUpdateCheckDefects = (defect_id, frequency) => {
        dispatch(postDefect({ fruit_id: null, test_id: check.test_id, defect_id, frequency: Number(frequency) || 0 }) as any);
    };

    let input_groups = useMemo(() => {
        return getFilledArrayOrDefault(location_config.visual_check_defect_form, []).map((i) => ({
            ...i,
            defects: doFilterDefects({ defect_position: i.defect_position, defect_severity: i.defect_severity }, all_defects)
        })).filter((i) => i.defects.length > 0);
    }, [all_defects, location_config.visual_check_defect_form]);

    input_groups = input_groups.map((g) => ({
        ...g,
        group_total: g.defects.reduce((total, i) => total + (Number(frequency[i.defect_id]) || 0), 0),
        form: g.defects.map((i) => ({
            label: i.label,
            value: frequency[i.defect_id] || 0,
            appendend_style,
            name: i.defect_id,
            image_url: i.image_url,
            type: "number",
            min: 0,
            max: check.sample_size,
            unity: toPercentage(frequency[i.defect_id], check.sample_size, g.decimals || 1),
        }))
    }));

    const first_column_width = getFilledArrayOrDefault(location_config?.visual_check_defect_form, [])[0]?.lg || 6;
    const decimals_for_total = Math.min(...input_groups.map((i) => i.decimals || 1));
    const total_defects = input_groups.filter((i) => !i.exclude_from_total).reduce((total: number, i) => total + i.group_total, 0);

    return <>
        <Row className="pb-3">
            {input_groups.map((group, index) => <Col key={index} className="pt-3" lg={group.lg}>
                <MetaForm
                    config={config}
                    meta={group.title ? [{ type: "header", label: group.title, options: group.defects }, ...group.form] : group.form}
                    object={frequency}
                    setValue={updateCheckDefectsState}
                    onDebounce={callUpdateCheckDefects}
                />
                {group.show_group_total > 0 && <div className="mb-2">
                    <Label>Total</Label>
                    <InputGroup >
                        <Input readOnly disabled type="number" className="text-start" value={group.group_total || ""} />
                        <InputGroupText css={css`width: 4.5rem;`}>{toPercentage(group.group_total, check.sample_size, group.decimals || 1)}</InputGroupText>
                    </InputGroup>
                </div>
                }
            </Col>)}
        </Row>
        {input_groups.length > 1
            && <Row lassName="pb-3">
                <Col c lg={first_column_width}>
                    <Label>Overall total</Label>
                    <div className="d-flex align-items-center">
                        <InputGroup >
                            <Input readOnly disabled type="number" className="text-start" value={total_defects || ""} />
                            <InputGroupText css={css`width: 4.5rem;`}>{toPercentage(total_defects, check.sample_size, decimals_for_total)}</InputGroupText>
                        </InputGroup>
                    </div>
                </Col>
            </Row>
        }
    </>;

};

export const DefectGroupSummary = () => {
    const config = useConfig();
    const check = useSelector((state: any) => state.checks.current);
    const layer = useSelector((state: any) => state.layers.current);
    const location_config = config.get_location(layer, check);
    const all_defects = useDefectsHook(layer.fruit_type);

    // * defect_group is the array to be used for reporting (can also include internal defect which you dont use in visual check)
    const { defect_groups } = location_config;
    const defect_group_map = defect_groups.map((defect_group, index) => <DefectGroup key={index} defect_group={defect_group} all_defects={all_defects} />);


    const formFields = location_config.visual_check_form.map((i) => ({ label: i.label, value: check[i.name] }));

    return [
        formFields.length > 0 && <div className="pe-5 align-self-start" key={0}>
            <table>
                <tbody>
                    {formFields.map((i, index) => <tr key={index}>
                        <th >{i.label}: </th>
                        <td className="ps-3 text-capitalize">{i.value}</td>
                    </tr>)}
                </tbody>
            </table>
        </div>,
        ...defect_group_map.map((i, index) => <div className="pe-5 align-self-start" key={index + 1}>
            {i}
        </div>).filter((i) => i)
    ];

};


// This is the html version of PDFCheckChildrenDefects
function DefectGroup({ defect_group, all_defects }) {
    const check = useSelector((state: any) => state.checks.current);
    const layer = useSelector((state: any) => state.layers.current);
    const group_defects = doFilterDefects({ defect_position: defect_group.defect_position, defect_severity: defect_group.defect_severity }, all_defects);
    const metric_meta_key = `defects_${[...(defect_group.defect_position || []), ...(defect_group.defect_severity || [])].join("_")}`;
    // * set this flag using the color coding wizard
    const total_flag_name = `flag_${metric_meta_key}`;
    const denominator = defect_group.denominator === "sample_size" ? check.sample_size : check.avocados.length;
    const flags = useMemo(() => applyTriggerFilterFlags(defect_group.flags || [], layer, check), [layer, check]);

    // * Use this function to get the flag color for indivudal defects
    const getFlag = useCallback((value) => {
        const flag = find_category(value / denominator, flags);
        if (flag) {
            return flag.flag;
        }
        return "";
    }, [flags, denominator]);


    // Defects registered on fruit by fruit
    const fruit_defects = check.avocados.flatMap((avocado) => avocado.defects);

    // We assume here that what ever is registered on a check level via visual check or ART is not also registered on a fruit level
    const registered_defects = [...check.defect_list, ...fruit_defects];

    const total_defects_per_type = registered_defects.reduce((acc, i) => ({ ...acc, [i.defect_id]: (acc[i.defect_id] || 0) + i.frequency }), {});

    const decimals = defect_group.decimals || 1;

    return <table>
        <tbody>
            <tr>
                <th css={css`max-width: 20rem;`} >
                    {defect_group.title}:
                </th>
                <td className="ps-3">
                    <ColorCodedSpan flag={check[total_flag_name]}> {metricPercentage(check[`${metric_meta_key}_relative`], decimals, "%")}</ColorCodedSpan>
                </td>
            </tr>
            {group_defects.filter((i) => {
                const numerator = total_defects_per_type[i.defect_id] || 0; // Use 0 if undefined
                const denominatorValue = denominator;

                // Calculate percentage
                if (denominatorValue && denominatorValue > 0) {
                    const percentage = (numerator / denominatorValue) * 100;
                    // Round the percentage to the specified decimals
                    const roundedPercentage = Math.round(percentage * 10 ** decimals) / 10 ** decimals;

                    return roundedPercentage > 0; // Filter out 0% values
                }

                return false; // Exclude if denominator is not valid
            }).map((i, index) => <tr key={index}>
                <th className="text-truncate" css={css`max-width: 20rem;`} >{i.label}:</th>
                <td className="ps-3">
                    <ColorCodedSpan flag={getFlag(total_defects_per_type[i.defect_id])}>{toPercentage(total_defects_per_type[i.defect_id], denominator, decimals)}</ColorCodedSpan>
                </td>
            </tr>)}

        </tbody>
    </table>;
}


export const OptionsForm = () => {
    const config = useConfig();
    const dispatch = useDispatch();
    const check = useSelector((state: any) => state.checks.current);
    const layer = useSelector((state: any) => state.layers.current);
    const location_config = config.get_location(layer, check);

    const fields = getFilledArrayOrDefault(location_config?.visual_check_option_form, []);

    const updateCheckValue = useCallback((field: string, value: any) => { dispatch(updateCheckState({ ...check, field, value })); }, [check, dispatch]);

    const callUpdateCheck = useCallback((field: string, value: any) => { dispatch(patchCheck(check.test_id, field, value) as any); }, [check, dispatch]);


    const options = useMemo(() => fields.map((item) => ({
        title: item.label,
        options_total: item.options.reduce((total, option) => total + (Number(check[`${item.name}_${option.label}`]) || 0), 0),
        form: item.options.map((option) => ({
            label: option.label,
            name: `${item.name}_${option.label}`,
            type: "number",
            value: check[item.name],
            unity: toPercentage(Number(check[`${item.name}_${option.label}`]), 100, 1),
            appendend_style,
        })),
        lg: 4,
        show_error_msg_if_total_is_not_100: item.show_error_msg_if_total_is_not_100,
    })), [fields, check]);

    return (
        <>
            <Row>
                {options.map((option, index) => <Col key={index} className="pt-3" lg={option.lg}>
                    <MetaForm
                        config={config}
                        meta={option.title ? [{ type: "header", label: option.title }, ...option.form] : option.form}
                        object={check}
                        setValue={updateCheckValue}
                        onDebounce={callUpdateCheck}
                    />
                    <div className="mb-2">
                        <Label>Total</Label>
                        <InputGroup >
                            <Input readOnly min={0} disabled type="number" className="text-start" value={option.options_total || ""} />
                            <InputGroupText css={css`width: 4.5rem;`}>{toPercentage(option.options_total, 100, 0 || 1)}</InputGroupText>
                        </InputGroup>
                        {option.show_error_msg_if_total_is_not_100 && <div className="position-relative">
                            {(Number(option.options_total) !== 100) && <small className="position-absolute text-danger">{"The total % should be 100%"}</small>}
                        </div>}
                    </div>
                </Col>)}
            </Row>
        </>
    );
};
