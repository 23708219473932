/** @jsxImportSource @emotion/react */
import { useMsal } from "@azure/msal-react";
import { css } from "@emotion/react";
import { faBars, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Link, NavLink, useLocation, useNavigate
} from "react-router-dom";
import { Button } from "reactstrap";
import { logoutUser } from "../../../actions/Auth/actions";
// TODO: Fix this import typescript error
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import logo from "../../../img/logo-experience-fruit-quality.png";
import { RootState } from "../../../reducers";

// // #region NavLinks
// interface GeneralLinkProps {
//     path: string;
//     label: string;
// }

// const GeneralLink = ({ path, label }: GeneralLinkProps) => {
//     const location = useLocation();
//     const isActive = location.pathname.startsWith(path);
//     return (
//         <li className={`nav-item ${isActive ? "active" : ""}`} key={path}>
//             <Link className={`nav-link ${isActive ? "active fw-bold" : ""}`} to={path}>{label}</Link>
//         </li>
//     );
// };

// export const headerLinks = {
//     reports: "reports",
//     login: "login",
//     logout: "logout",
// };


const useMainLinks = () => [
    // <GeneralLink key={headerLinks.reports} path={`/reports`} label={"Reports"} />
];
const useSecondaryLinks = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { authenticated, isLoading } = useSelector<RootState, any>((state) => state.auth);
    const dispatch = useDispatch();
    const { instance } = useMsal();

    const logoutHandler = () => {
        // make sure to redirect to login so that the redirect_to=/add-check/134/summary param is not set to the currect page
        navigate("/login");
        dispatch(logoutUser());
        instance.logoutRedirect();
    };

    if (!authenticated) {
        // also show login item when loading, this will prevent "flickering" of the header during page load
        return [
            (location.pathname !== "/login" || isLoading) && <li className="nav-item" key="login">
                <NavLink className="nav-link" to="/login" >Login</NavLink>
            </li>,
        ];
    }
    return [
        <li className="nav-item" key="logout">
            <button className="nav-link link btn btn-link " onClick={() => logoutHandler()}><span className="text-danger">Logout</span></button>
        </li>
    ];

};
// #endregion

// #region NavToggle
const NavItemCSS = css`
    color: black;
    min-width: 2.5rem;
`;

interface NavToggleProps {
    open: boolean;
    setOpen: (open: boolean) => void;
}

function NavToggle({ open, setOpen }: NavToggleProps) {
    return (
        <Button color="light" css={NavItemCSS} onClick={() => setOpen(!open)}>
            {!open && <span><FontAwesomeIcon icon={faBars} /></span>}
            {open && <span><FontAwesomeIcon icon={faTimes} /></span>}
        </Button>
    );
}
// #endregion

// #region Header
export interface HeaderProps {
    blueBackground?: boolean;
}

export default function Header({ blueBackground }: HeaderProps) {
    const [open, setOpen] = useState(false);

    const mainLinks = useMainLinks();
    const secondaryLinks = useSecondaryLinks();

    let backgroundColor = "white";
    if (blueBackground) {
        backgroundColor = open ? "#f8f8f8" : "#f4f9ff";
    }

    return (
        <div
            css={css`
                box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.12);
                transition: color 0.3s ease-in;
                background-color: ${backgroundColor};
            `}
        >
            <div className="container py-4">
                {/* MOBILE NAV */}
                <div className="d-lg-none">
                    <div
                        className="d-flex align-items-start justify-content-between"
                        css={css`
                            min-height: 3.6rem;
                        `}
                    >
                        <div>
                            <Link to={`/reports`}>
                                <img
                                    src={logo}
                                    alt=""
                                    css={css`
                                        height: 48px;
                                        padding-bottom: 8px;
                                    `}
                                />
                            </Link>
                        </div>
                        <div>
                            <NavToggle open={open} setOpen={setOpen}></NavToggle>
                        </div>
                    </div>
                    {open && (
                        <div className="w-100">
                            <div className="navbar">
                                <ul
                                    className="navbar-nav w-100"
                                    css={css`
                                        display: flex;
                                        flex-direction: column;
                                        align-items: flex-end; /* Right-align secondary links */
                                    `}
                                >
                                    {mainLinks}
                                    {secondaryLinks}
                                </ul>
                            </div>
                        </div>
                    )}
                </div>

                {/* TABLET + DESKTOP NAV */}
                <div
                    className="d-none d-lg-flex align-items-center"
                    css={css`
                        min-height: 3.6rem;
                    `}
                >
                    {/* Logo */}
                    <div>
                        <Link to={`/reports`}>
                            <img
                                src={logo}
                                alt=""
                                css={css`
                                    height: 48px;
                                    padding-bottom: 8px;
                                `}
                            />
                        </Link>
                    </div>

                    {/* Main Links */}
                    {!open && (
                        <div
                            css={css`
                                flex-grow: 1;
                                padding-left: 2em;
                            `}
                        >
                            <div className="navbar navbar-expand-lg">
                                <ul className="d-flex navbar-nav justify-content-start">
                                    {mainLinks}
                                </ul>
                            </div>
                        </div>
                    )}

                    {/* Secondary Links (Toggled View) */}
                    {open && (
                        <div
                            className="ms-auto"
                            css={css`
                                flex-grow: 1;
                            `}
                        >
                            <div className="navbar navbar-expand-lg">
                                <ul
                                    className="d-flex navbar-nav justify-content-end"
                                    css={css`
                                        flex-grow: 1;
                                    `}
                                >
                                    {secondaryLinks}
                                </ul>
                            </div>
                        </div>
                    )}

                    {/* Toggle Button */}
                    <div
                        className="ms-3"
                        css={css`
                            margin-left: auto;
                        `}
                    >
                        <NavToggle open={open} setOpen={setOpen}></NavToggle>
                    </div>
                </div>
            </div>
        </div>
    );
}

// #endregion
