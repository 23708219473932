import { LIST } from "./actionsTypes";

export interface Notification {
    id: string;
    type: string;
    tenant_id: string;
    subject: string;
    body: string;
    attachments: unknown[];
    sender: string;
    recipients: object;
    external_id: string;
    status: string;
    retry_count: number;
    attributes: {
        report?: object
    };
    created: string;
    updated: string;
    attachment_urls: string[];
}

export interface MailboxState {
    isLoading: boolean;
    items: {
        results: Notification[];
        count: number;
    };
    filter: {
        offset: number;
        limit: number;
    };
}

const initialState = {
    isLoading: false,
    items: {
        results: [],
        count: 0,
    },
    filter: { offset: 0, limit: 25 },
};

export default (state: MailboxState = initialState, action): MailboxState => {
    switch (action.type) {
    case LIST.LOADING:
        return { ...state, isLoading: true };
    case LIST.ERROR:
        return { ...state, isLoading: false };
    case LIST.SUCCESS:
        return { ...state, isLoading: false, items: action.payload.data };
    case LIST.FILTER:
        return { ...state, filter: action.payload as any };
    default:
        return state;
    }
};
