/* eslint-disable no-case-declarations */
import { Reducer } from "redux";
import { DELETE, LIST, UPDATE, CREATE } from "./actionTypes";
import { SELECT } from "../tenant/actionTypes";
import { createEntityReducer, initialState } from "../../Admin/entityReducer";
import { Entity, EntityState } from "../../Admin/reducer";
import { AvosAction } from "../../types";


export interface Report extends Entity{
    id: string;
    fruit_type: string;
    client: string;
    subject: string;
    url: string;
    created: string;
}

export type ReportState = EntityState<Report>;

const baseReducer = createEntityReducer<ReportState>({ LIST, CREATE, UPDATE, DELETE });

const customReducer = ((state: ReportState = { ...initialState, localFiltering: false } as ReportState, action: AvosAction): ReportState => {
    switch (action.type) {
    case SELECT.SUCCESS:
        return {
            ...state,
            reload: true,
        };
    default:
        return baseReducer(state, action);
    }
}) as Reducer<ReportState>;

export default customReducer;
