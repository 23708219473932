/** @jsxImportSource @emotion/react */
import { faExchange, faPlus } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dayjs from "dayjs";
import Papa from "papaparse";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
    Button, Col, Container,
    FormGroup, FormText,
    Input,
    Label,
    Modal, ModalBody, ModalFooter, ModalHeader, Row, Table
} from "reactstrap";
import { DEFECT_POSITION, DEFECT_SEVERITY } from "../../../actions/Checks/constants";
import { createTenantDefect, deleteAllTenantDefect, deleteTenantDefect, getTenant, getTenantDefects, importTenantDefect, updateTenantDefect } from "../../../actions/Tenants/actions";
import useConfig from "../../../actions/Tenants/config/configHook";
import MetaForm from "../../Forms/MetaForm";
import { Loading } from "../../Helper/Loading";
import TenantPageWrappedInConfigProvider from "./TenantPageWrappedInConfigProvider";

const defaultValues = {
    country: null,
    supplier: null,
    global_gap_number: null,
    label: "",
    value: "",
    image_url: null,

};

const defaultCsvOption = {
    country: null,
    supplier: null,
    fruit_type: null,
    global_gap_number: null,
    label: null,
    value: null,
    image_url: null
};

// * Wrap this page in the config of the tenant we are editing
export default function WrappedInConfigProviderTenantEditForm() {
    return <TenantPageWrappedInConfigProvider>
        <TenantEditDefectsForm />
    </TenantPageWrappedInConfigProvider>;
}

function TenantEditDefectsForm() {
    const current = useSelector((state) => state.tenants.current);
    const options = useSelector((state) => state.tenants.defects);
    const isLoading = useSelector((state) => state.tenants.isLoading);
    const [isOpen, setModal] = useState(false);
    const [importModal, setImportModal] = useState(false);
    const params = useParams();
    const dispatch = useDispatch();
    const [query, setQuery] = useState(defaultValues);
    const [showAllToBeImportedItems, setShowAllToBeImportedItems] = useState(false);
    const navigate = useNavigate();
    const config = useConfig();
    const [filter, setFilter] = useState({ fruit_type: params.fruit_type, position: Object.values(DEFECT_POSITION), severity: Object.values(DEFECT_SEVERITY) });


    const { tenant_name } = current;
    const { fruit_type, tenant_id } = params;

    const [importData, setImportData] = useState([]);
    const [selectedFile, setSelectedFile] = useState(false);
    const filteredDefects = useMemo(() => {
        if (options) {
            return options.filter((i) => filter.position.includes(i.position) && filter.severity.includes(i.severity));
        }
        return [];
    }, [options, filter, fruit_type]);

    // * refresh the tenant object if needed
    useEffect(() => {
        if (tenant_id) {
            dispatch(getTenant(tenant_id));
        }
    }, [tenant_id]);

    // * fetch the fruit_type options that we are currently editing
    useEffect(() => {
        if (tenant_id && fruit_type) {
            dispatch(getTenantDefects(tenant_id, fruit_type));
        }
    }, [fruit_type, tenant_id]);

    // * if the fruit_type changes, navigate to the new fruit_type
    const first_fruit_type = config?.fruit_types?.[0]?.value;
    useEffect(() => {
        if (filter.fruit_type !== fruit_type && filter.fruit_type) {
            navigate(`/tenants/${tenant_id}/defects/${filter.fruit_type}`);
        } else if (!fruit_type && first_fruit_type) {
            // * if no fruit_type is set, filter for the first fruit_type
            setFilter((prev) => ({ ...prev, fruit_type: first_fruit_type }));
        }
    }, [filter.fruit_type, fruit_type, first_fruit_type]);

    const fileChangeHandler = (e) => {
        const file = e.target.files[0];

        if (file) {
            Papa.parse(file, {
                header: true,
                skipEmptyLines: true,
                complete(results) {
                    const options = results.data.map((i) => ({
                        ...defaultCsvOption,
                        position: i.position ? i.position.trim().toLowerCase() : null,
                        severity: i.severity ? i.severity.trim().toLowerCase() : null,
                        fruit_type: i.fruit_type ? i.fruit_type.trim().toLowerCase() : fruit_type,
                        label: i.label ? i.label.trim() : null,
                        description: i.description ? i.description.trim() : null,
                        value: i.value || i.label.trim().replaceAll(" ", "-").toLowerCase(),
                        image_url: i.image_url ? i.image_url : null
                    })).map((i) => ({
                        ...i,
                        defect_id: `${i.position}_${i.severity}_${i.value}`
                    }));

                    setImportData(options);
                    setSelectedFile(file);
                },
            });
        }
    };

    const handleImport = () => dispatch(importTenantDefect(params.tenant_id, fruit_type, filteredImportData));

    const fruitOptions = config.fruit_types.map((i) => ({ value: i.value, label: i.text }));
    const form = [
        {
            label: "Position",
            name: "position",
            type: "radio",
            default: "internal",
            options: Object.values(DEFECT_POSITION).map((i) => ({ value: i, label: i })),
        },
        {
            label: "Severity",
            name: "severity",
            type: "radio",
            default: "minor",
            options: Object.values(DEFECT_SEVERITY).map((i) => ({ value: i, label: i })),
        },
        {
            label: "Label",
            name: "label",
            type: "text",
        },
        {
            label: "Description",
            name: "description",
            type: "text",
        },
        {
            label: "Value",
            name: "value",
            type: "text",
            onchange_callback: ({ setValue, field, value }) => {
                setValue(field, value.replaceAll(" ", "-").replaceAll("_", "-").toLowerCase());
            },
        },
        {
            label: "Defect image",
            name: "image_url",
            description: "Upload defect image. Webp format only. https://cloudinary.com/tools/image-to-webp",
            type: "file-select",
            accept: ["image/webp"],
            upload_config_image: true
        }
    ];

    const deleteOption = (option) => dispatch(deleteTenantDefect(tenant_id, option.fruit_type, option.id));

    const deleteAll = () => dispatch(deleteAllTenantDefect(tenant_id, fruit_type));

    const filterForm = [
        {
            label: "Fruit type",
            name: "fruit_type",
            type: "single-select",
            options: fruitOptions,
            lg: 4,
        },
        {
            label: "Position",
            name: "position",
            type: "single-select",
            return_array: true,
            multi: true,
            lg: 4,
            options: Object.values(DEFECT_POSITION).map((i) => ({ value: i, label: i })),
        },
        {
            label: "Severity",
            name: "severity",
            type: "single-select",
            return_array: true,
            multi: true,
            lg: 4,
            options: Object.values(DEFECT_SEVERITY).map((i) => ({ value: i, label: i })),
        }
    ];
    const cancel = () => {
        setModal(false);
        setQuery(defaultValues);
    };

    const newItem = () => {
        setModal(true);
        setQuery(defaultValues);
    };
    const onSubmit = () => {

        if (query.id) {
            dispatch(updateTenantDefect(tenant_id, fruit_type, query));
        } else {
            dispatch(createTenantDefect(tenant_id, fruit_type, { ...query, fruit_type }));
        }
        setModal(true);
        setQuery(defaultValues);
    };

    const exportData = (data, fileName, type) => {
        // Create a link and download the file
        const blob = new Blob([data], { type });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
    };

    const exportOptions = () => {
        const csvData = Papa.unparse(options);
        exportData(csvData, `export-${tenant_name}-${dayjs().format("DD-MMM-YYYY-HHmm")}-${params.fruit_type}-options.csv`, "text/csv;charset=utf-8;");
    };

    const filteredImportData = importData.filter((i) => i.label && i.value);

    if (!options && isLoading) return <Loading></Loading>;
    return (
        <Container className="py-5">
            <Row className="justify-content-center">
                <Col lg="12">
                    <div className="d-flex justify-content-between">
                        <div>
                            <h2 className="">Defects</h2>
                            <p className="lead">Edit tenant <b>{tenant_name}</b> defects for <b>{fruit_type}</b></p>
                        </div>
                        <div>
                            <Button className="text-nowrap me-3" color="primary" onClick={() => newItem()}><FontAwesomeIcon icon={faPlus} /> Add option</Button>
                            <Button className="text-nowrap me-3" color="primary" onClick={() => setImportModal(true)}><FontAwesomeIcon icon={faExchange} /> Import</Button>
                            <Button className="btn-close my-1" size="lg" onClick={() => navigate(`/tenants/${tenant_id}`)} ></Button>
                        </div>
                    </div>
                </Col>
            </Row>
            <div>
                <MetaForm
                    meta={filterForm}
                    grid={true} object={filter}
                    setValue={(field, value) => setFilter((oldFilter) => ({ ...oldFilter, [field]: value }))} />
            </div>
            <Row className="justify-content-center py-5">
                <Col lg="12">
                    <Table size="sm">
                        <thead>
                            <tr>
                                <th>pk</th>
                                <th>defect_id</th>
                                <th>Position</th>
                                <th>Severity</th>
                                <th>Value</th>
                                <th>Label</th>
                                <th>Description</th>
                                <th>Defect image</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {(filteredDefects || []).map((i, index) => (
                                <tr key={index} >
                                    <td className="align-middle">{i.id.slice(0, 7)}</td>
                                    <td className="align-middle ">{i.defect_id}</td>
                                    <td className="align-middle">{i.position}</td>
                                    <td className="align-middle">{i.severity}</td>
                                    <td className="align-middle">{i.value}</td>
                                    <td className="align-middle">{i.label}</td>
                                    <td className="align-middle">{i.description}</td>
                                    <td className="align-middle"> {i?.image_url ? <img
                                        src={i?.image_url}
                                        alt="Selected image"
                                        className="file-preview-image ms-3"
                                        width="80px"
                                        height="80px"
                                    /> : "No image"}</td>
                                    <td className="text-end">
                                        <Button color="primary" className="me-1 mb-1" outline onClick={() => {
                                            setQuery(i);
                                            setModal(true);
                                        }}>Edit</Button>
                                        <Button color="danger" className="me-1 mb-1" outline onClick={() => deleteOption(i)}>Delete</Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Col>
            </Row>
            <Modal size="md" isOpen={isOpen} toggle={() => cancel()} >
                <ModalHeader toggle={() => cancel()} >{query.id ? "Edit" : "New"} {fruit_type} defect</ModalHeader>
                <ModalBody>
                    <MetaForm meta={form} setValue={(field, value) => setQuery((query) => ({ ...query, [field]: value }))} object={query} config={config} />
                </ModalBody>
                <ModalFooter>
                    <Button color="light" onClick={() => cancel()}>Cancel</Button>
                    <Button color="primary" disabled={!query.label || !query.value || isLoading} className="me-1 mb-1" onClick={() => onSubmit()}>Submit</Button>
                </ModalFooter>
            </Modal >

            <Modal size="xl" isOpen={importModal} toggle={() => setImportModal(false)} >
                <ModalHeader toggle={() => setImportModal(false)} >Import / Delete all</ModalHeader>
                <ModalBody>

                    <FormGroup>
                        <Label for="exampleFile">CSV</Label>
                        <Input
                            id="exampleFile"
                            name="file"
                            type="file"
                            onChange={fileChangeHandler}
                        />

                        {selectedFile ? (
                            <div className="py-3">
                                <FormText >
                                    <div>Filename: {selectedFile.name}</div>
                                    <div>Filetype: {selectedFile.type}</div>
                                    <div>Size in bytes: {selectedFile.size}</div>
                                    <div>Items: {importData && importData.length}</div>
                                    <div>Valid items: {importData && filteredImportData.length}</div>
                                </FormText>
                            </div>

                        ) : (<FormText>Upload csv file without headers here.</FormText>)}
                    </FormGroup>

                    {importData.length > 0 && <Table size="sm" responsive>
                        <thead>
                            <tr>
                                <th>Fruit type</th>
                                <th>defect_id</th>
                                <th>Position</th>
                                <th>Severity</th>
                                <th>Value</th>
                                <th>Label</th>
                                <th>Description</th>
                                <th>defect image</th>
                            </tr>
                        </thead>
                        <tbody>
                            {importData.slice(0, showAllToBeImportedItems ? importData.length : 10).map((i, index) => (
                                <tr key={index} >
                                    <td className={`align-middle ${i.fruit_type || "bg-danger"}`}>{i.fruit_type}</td>
                                    <td className={`align-middle ${i.defect_id || "bg-danger"}`}>{i.defect_id}</td>
                                    <td className={`align-middle ${i.position || "bg-danger"}`}>{i.position}</td>
                                    <td className={`align-middle ${i.severity || "bg-danger"}`}>{i.severity}</td>
                                    <td className={`align-middle ${i.value || "bg-danger"}`}>{i.value}</td>
                                    <td className={`align-middle ${i.label || "bg-danger"}`}>{i.label}</td>
                                    <td className={`align-middle ${i.description || "bg-danger"}`}>{i.description}</td>
                                    <td className={`align-middle ${i.image_url || "bg-danger"}`}>{i.image_url}</td>

                                </tr>
                            ))}
                        </tbody>
                    </Table>}
                    {importData.length > 10 && !showAllToBeImportedItems
                        && <div className="text-center py-3">
                            <span onClick={() => setShowAllToBeImportedItems(true)} className="btn-link">Show all</span>
                        </div>
                    }

                </ModalBody>
                <ModalFooter>
                    <Button color="light" onClick={() => setImportModal(false)}>Cancel</Button>
                    <Button color="light" className="me-1 mb-1" onClick={() => exportOptions()}>Export</Button>
                    <Button color="primary" disabled={isLoading || !importData || filteredImportData.length === 0} className="me-1 mb-1" onClick={() => handleImport()}>Import</Button>
                    <Button color="danger" disabled={isLoading} className="me-1 mb-1" onClick={() => deleteAll()}>Delete All</Button>
                </ModalFooter>
            </Modal >

        </Container>
    );
}
