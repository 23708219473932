/* eslint-disable @typescript-eslint/no-non-null-assertion */
/** @jsxImportSource @emotion/react */
import { useSelector, useDispatch } from "react-redux";
import dayjs from "dayjs";
import { getReports, updateReportsFilter } from "../../../actions/SupplierPortal/report/actions";
import { RootState } from "../../../reducers";
import { PageBase, PageBaseTableRowField } from "../Admin/PageBase";
import { Filter } from "../../../actions/Admin/reducer";
import { ReportState, Report } from "../../../actions/SupplierPortal/report/reducer";
import { Tenant } from "../../../actions/SupplierPortal/tenant/reducer";
import AttachmentLink from "../../Helper/AttachmentLink";
import { formatDateTime } from "../Layers/fields/formatters";

const ReportsPage = () => {
    const dispatch = useDispatch();
    const reportState = useSelector<RootState, ReportState>((state) => state.supplierPortal.report);
    const selectedTenant = useSelector<RootState, Tenant | undefined>((state) => state.supplierPortal.tenant.selected);

    const onUpdateFilter = (filter: Filter) => {
        dispatch(updateReportsFilter(filter));
    };
    const onListEntities = (filter: Filter) => {
        if (selectedTenant) dispatch(getReports(selectedTenant.id, filter));
    };
    const tableRow: PageBaseTableRowField<Report>[] = [
        {
            label: "Subject",
            name: "subject",
            lg: 4,
        },
        {
            label: "URL",
            name: "url",
            display_component: (value) => <AttachmentLink url={value.url} />,
            lg: 6,
        },
        {
            label: "Created",
            name: "created",
            lg: 2,
            display_component: (value) => formatDateTime(dayjs(value.created)),
        }
    ];

    const createForms = [];
    return <PageBase<Report>
        title="Reports"
        tableRow={tableRow}
        actionsColumnWidth={1}
        createForms={createForms as any}
        state={reportState}
        events={{ onUpdateFilter, onListEntities }}
        createable={false}
    />;
};


export default ReportsPage;


