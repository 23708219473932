/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { faBarcodeRead, faPlus } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { Button } from "reactstrap";
import { createLayer } from "../../../actions/Layers/actions";
import useConfig from "../../../actions/Tenants/config/configHook";
import { defaultLayerHook } from "./FindLayerBox";
import { CreateLayerModal } from "./Layer/CreateLayer";

const buttonStyle = css`
    padding: 0.5rem 1rem;
    font-size: 1.2rem;
`;

export default function ScanLayerButon() {
    const config = useConfig();
    const dispatch = useDispatch();

    const [layer, setLayer] = defaultLayerHook();
    const [layerModalOpen, setLayerModal] = useState<false | { withScanner: boolean }>(false);
    const navigate = useNavigate();

    const layer_config = config.get_layer_config(layer);

    const _createLayer = () => {
        const layer_config = config.get_layer_config(layer);
        Promise.resolve(
            dispatch(createLayer(layer)),
        ).then((response) => {
            if (response.payload) {
                const layer = response.payload.data;
                navigate(`/layer/${layer.id}/action/${layer_config.start_action || "edit"}`);
            }
        });
    };

    const setValue = (field, value) => setLayer((object) => ({ ...object, [field]: value }));

    const onClose = () => setLayerModal(false);

    // * If the scanned/searched layer already exists, navigate to the layer page
    const setLayerSelect = (select) => {
        if (select.id) {
            navigate(`/layer/${select.id}`);
            return;
        }
        // * if it doesn't exist, open the create layer modal
        setLayer(select);
        setLayerModal({ withScanner: false });
    };

    if (!layer_config) {
        return null;
    }
    return <>
        <CreateLayerModal
            isOpen={layerModalOpen}
            onClose={onClose}
            onSubmit={_createLayer}
            layer={layer}
            setLayerSelect={setLayerSelect}
            setValue={setValue}
            disableSaveButton={!layer.label || !layer.type}
        />
        <div className="d-flex align-items-center justify-content-between justify-content-lg-start">
            <div className="mt-4">
                {config.use_barcode_scanner
                    && <Button color="primary" className="text-nowrap" css={buttonStyle} onClick={() => setLayerModal({ withScanner: true })} >
                        Perform check <FontAwesomeIcon icon={faBarcodeRead} />
                    </Button>
                }
                {!config.use_barcode_scanner && <Button color="primary" className="text-nowrap" onClick={() => setLayerModal({ withScanner: false })} >
                    <FontAwesomeIcon size="10x" icon={faPlus} />
                </Button>
                }
            </div>
        </div>
    </>;
}
