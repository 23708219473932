/** @jsxImportSource @emotion/react */

import {
    summary_field_atron_weight,
    summary_field_avocado_index,
    summary_field_avos_firmness,
    summary_field_avos_firmness_raw,
    summary_field_box_size,
    summary_field_brix,
    summary_field_caliber,
    summary_field_color,
    summary_field_dry_matter,
    summary_field_dry_matter_felix,
    summary_field_dry_matter_class,
    summary_field_durometer_pressures,
    summary_field_external_defects,
    summary_field_flavour_creamy,
    summary_field_flavour_nuttiness,
    summary_field_fruit_firmness_class,
    summary_field_fruit_variety,
    summary_field_gross_weight,
    summary_field_internal_color,
    summary_field_external_color,
    summary_field_internal_defects,
    summary_field_is_shiny,
    summary_field_lab_check_dm_avg,
    summary_field_major_defects,
    summary_field_manual_quality,
    summary_field_manual_ripening_stage,
    summary_field_maturity,
    summary_field_minor_defects,
    summary_field_mouth_ripeness,
    summary_field_net_weight,
    summary_field_penetro_pressures,
    summary_field_plu,
    summary_field_predicted_ripening_stage,
    summary_field_pressure,
    summary_field_pressure_classes,
    summary_field_pressure_color_code_by_firmness_class,
    summary_field_pressure_prediction,
    summary_field_ripening_stage_firmness,
    summary_field_taste,
    summary_field_temperature,
    summary_field_underweight,
    summary_field_weight,
    summary_field_weight_kg,
    summary_field_first_impression,
    summary_field_texture_consistency,
    summary_field_colors,
    summary_field_maturity_string,
    summary_field_box_weight_empty,
    summary_field_box_weight_gross,
    summary_field_box_index,
    summary_field_box_weight_net,
    summary_field_fruit_diameter,
    summary_field_lenticel,
    summary_field_translucency,
    summary_field_remarks,
    summary_field_acidity
} from "../../../../actions/Tenants/config/constants";
import FilterableConfigList from "./FilterableConfigList";


// TODO refactor summary fields to FRUIT.PRESSURE api thing
const fruit_summary_fields = [
    {
        value: summary_field_avocado_index,
        label: "Fruit sample"
    },
    {
        value: summary_field_box_index,
        label: "Box sample"
    },
    {
        value: summary_field_fruit_variety,
        label: "Variety"
    },
    {
        value: summary_field_avos_firmness,
        label: "Firmness"
    },
    {
        value: summary_field_avos_firmness_raw,
        label: "Raw firmness"
    },
    {
        value: summary_field_pressure_color_code_by_firmness_class,
        label: "Pressure"
    },
    {
        value: summary_field_pressure,
        label: "Pressure"
    },
    {
        value: summary_field_pressure_prediction,
        label: "Pressure"
    },
    {
        value: summary_field_external_defects,
        label: "Ext. def"
    },
    {
        value: summary_field_internal_defects,
        label: "Int. def"
    },
    {
        value: summary_field_color,
        label: "Color"
    },
    {
        value: summary_field_internal_color,
        label: "Internal color"
    },
    {
        value: summary_field_external_color,
        label: "External color"
    },
    {
        value: summary_field_weight,
        label: "Weight gram"
    },
    {
        value: summary_field_weight_kg,
        label: "Weight"
    },
    {
        value: summary_field_brix,
        label: "Brix"
    },
    {
        value: summary_field_maturity,
        label: "Maturity"
    },
    {
        value: summary_field_taste,
        label: "Taste"
    },
    {
        value: summary_field_temperature,
        label: "Pulp temp"
    },
    {
        value: summary_field_dry_matter,
        label: "Dry matter"
    },
    {
        value: summary_field_dry_matter_felix,
        label: "Dry matter felix"
    },
    {
        value: summary_field_dry_matter_class,
        label: "Dry matter class"
    },
    {
        value: summary_field_predicted_ripening_stage,
        label: "Ripening class"
    },
    {
        value: summary_field_ripening_stage_firmness,
        label: "Firmness class"
    },
    {
        value: summary_field_fruit_firmness_class,
        label: "Firmness class"
    },
    {
        value: summary_field_manual_ripening_stage,
        label: "Ripening class"
    },
    {
        value: summary_field_manual_quality,
        label: "Quality"
    },
    {
        value: summary_field_is_shiny,
        label: "Shiny/Matte"
    },
    {
        value: summary_field_atron_weight,
        label: "Weight"
    },
    {
        value: summary_field_penetro_pressures,
        label: "Penetrometer"
    },
    {
        value: summary_field_durometer_pressures,
        label: "Durometer"
    },
    {
        value: summary_field_pressure_classes,
        label: "Pressure class"
    },
    {
        value: summary_field_plu,
        label: "PLU"
    },
    {
        value: summary_field_box_size,
        label: "Size"
    },
    {
        value: summary_field_mouth_ripeness,
        label: "Mouth ripeness"
    },
    {
        value: summary_field_flavour_nuttiness,
        label: "Flavour nuttiness"
    },
    {
        value: summary_field_flavour_creamy,
        label: "Flavour creamy"
    },
    {
        value: summary_field_lab_check_dm_avg,
        label: "Average DM"
    },
    {
        value: summary_field_major_defects,
        label: "Major defects"
    },
    {
        value: summary_field_minor_defects,
        label: "Minor defects"
    },
    {
        value: summary_field_underweight,
        label: "Underweight"
    },

    {
        value: summary_field_gross_weight,
        label: "Gross weight"
    },
    {
        value: summary_field_net_weight,
        label: "Net weight"
    },
    {
        value: summary_field_caliber,
        label: "Caliber"
    },
    {
        value: summary_field_first_impression,
        label: "First impression"
    },
    {
        value: summary_field_texture_consistency,
        label: "Texture consistency"
    },
    {
        value: summary_field_colors,
        label: "Color"
    },
    {
        value: summary_field_maturity_string,
        label: "Maturity"
    },
    {
        value: summary_field_box_weight_empty,
        label: "Box weight empty"
    },
    {
        value: summary_field_box_weight_gross,
        label: "Box weight gross"
    },
    {
        value: summary_field_box_weight_net,
        label: "Box weight net"
    },
    {
        value: summary_field_fruit_diameter,
        label: "Diameter"
    },
    {
        value: summary_field_lenticel,
        label: "Lenticel"
    },
    {
        value: summary_field_translucency,
        label: "Translucency"
    },
    {
        value: summary_field_remarks,
        label: "Remarks"
    },
    {
        value: summary_field_acidity,
        label: "Acidity"
    },
];

const defaultField = {
    field: {
        field_id: summary_field_avocado_index,
        label: "Fruit sample"
    },
    display_filter: {
        fruit_types: [],
        exclude_fruit_types: [],
        layer_types: [],
        // exclude_layer_types: [],
    }
};
export default function CheckSummaryFruitTableConfigForm({ name, title, fields, description, setArray }) {
    const getFieldTitle = (object) => <span>{object.label} [{object.field_id}]</span>;
    const getFieldForm = (object, setObject) => [
        {
            label: "Field",
            type: "single-select",
            name: "field_id",
            options: fruit_summary_fields.map((i) => ({ value: i.value, label: `${i.label} [${i.value}]` })),
            onchange_callback: ({ setValue, field, value }) => {
                const theField = fruit_summary_fields.find((i) => i.value === value);
                if (!theField) {
                    setValue(field, value);
                    return;
                }
                setObject({ ...object, field_id: theField.value, label: theField.label });
            },

        },
        {
            label: "Label",
            type: "text",
            name: "label",
        }
    ];
    return <FilterableConfigList name={name} title={title} description={description} fields={fields} setArray={setArray} defaultField={defaultField} getFieldForm={getFieldForm} getFieldTitle={getFieldTitle} />;

}


