/** @jsxImportSource @emotion/react */
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, ListGroup, ListGroupItem, Row } from "reactstrap";
import { Tenant } from "../../../actions/SupplierPortal/tenant/reducer";
import { RootState } from "../../../reducers";
import { selectTenant, getTenants } from "../../../actions/SupplierPortal/tenant/actions";
import Reports from "./Reports";

export default function SupplierPortalPage() {
    const dispatch = useDispatch();
    const selectedTenant = useSelector<RootState, Tenant | undefined>((state) => state.supplierPortal.tenant.selected);
    const tenants = useSelector<RootState, Tenant[]>((state) => state.supplierPortal.tenant.items);
    const onTenantSelect = (tenant:Tenant) => {
        dispatch(selectTenant(tenant));
    };
    useEffect(() => {
        if (tenants.length > 0 && !selectedTenant) {
            dispatch(selectTenant(tenants[0]));
        }
    }, [tenants]);
    useEffect(() => {
        dispatch(getTenants());
    }, []);
    return <Container className="py-md-5 py-3">
        <Row>
            {/* Left Side: Tenants */}
            <Col xs="3" sm="3" md="2" className="border-right p-3">
                <h4 className="border-bottom">Clients</h4>
                <ListGroup>
                    {tenants.map((tenant, index) => (
                        <ListGroupItem key={index} tag="button" action onClick={() => onTenantSelect(tenant)} active={tenant.id === selectedTenant?.id} >
                            {tenant.label}
                        </ListGroupItem>
                    ))}
                </ListGroup>
            </Col>

            {/* Right Side: Reports */}
            <Col xs="9" sm="9" md="10" className="p-3">
                <h4 className="border-bottom">Reports</h4>
                <Reports />
            </Col>
        </Row>
    </Container >;
}
