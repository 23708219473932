
export function formatMeta2Frontend(data) {
    const { meta, conditions, ...ripening_cell } = data;
    meta.forEach((i) => {
        ripening_cell[i.key] = i.value;
    });

    // TODO: discuss this could cause colission with other keys defined above
    conditions.forEach((i) => {
        ripening_cell[i.key] = i.value;
    });

    return { ...ripening_cell };
}

export function formatCondition2Frontend(dataArray) {
    return dataArray.reduce((acc, data) => {
        const { key, value } = data;
        acc[key] = value;
        return acc;
    }, {});
}
