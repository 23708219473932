/* eslint-disable no-case-declarations */
import { combineReducers } from "redux";
import reportReducer, { ReportState } from "./report/reducer";
import tenantReducer, { TenantState } from "./tenant/reducer";
import { Filter } from "../Admin/reducer";


export interface SupplierPortalState {
    report: ReportState;
    tenant: TenantState;
}

export const defaultFilter: Filter = { offset: 0, limit: 25 };

export default combineReducers({
    report: reportReducer,
    tenant: tenantReducer,
});
