/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { faPaste, faTrash } from "@fortawesome/pro-regular-svg-icons";
import { faCopy } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { createDuplicateAvocado, createManualAvocado, registerDefects, resetAvocadoState } from "../../../../actions/Avocados/actions";
import useConfig from "../../../../actions/Tenants/config/configHook";
import { Loading } from "../../../Helper/Loading";
import Box from "./Layout";

import { triggerCheckBusinessRules } from "../../../../actions/Checks/actions";
import { isArtLocation, UNIT_OF_ACCOUNT } from "../../../../actions/Layers/constants";
import { path_advance_ripening, path_manual_fruit_form, path_summary } from "../../../../actions/Tenants/config/constants";
import { FormField } from "../../../../actions/Tenants/config/constantsTyped";
import { isFilledArray } from "../../../../utils";
import { useEffectOnce } from "../../../../utils/useEffectOnce";
import MetaForm, { metaFormIsValid } from "../../../Forms/MetaForm";
import { doFilterDefects, useDefectsHook } from "../../../Forms/useManagedOptionsHook";
import usePatchAvocado from "../usePatchAvocado";
import { DeprecatedManualForm } from "./DeprecatedManualCheckForm";
import { InvalidFruitModal } from "./InvalidFruitModal";

export default function ManualFruitForm() {
    const check = useSelector((state: any) => state.checks.current);
    const isLoading = useSelector<any, boolean>((state: any) => state.checks.isLoading);
    const fruitLoading = useSelector((state: any) => state.avocados.isUpdating);
    const layer = useSelector((state: any) => state.layers.current);
    const config = useConfig();
    const location = config.get_location(layer, check);
    const checkLocationUnit = location?.unit_of_account || UNIT_OF_ACCOUNT.FRUIT;
    // const [useGrid, setUseGrid] = useState((check?.use_bulk_edit || 0) > 1);
    const useGrid = (check?.use_bulk_edit || 0) > 1;
    const useCopyPasteModal = (useGrid && location?.bulk_input_modal);
    const [bulkCopyPasteModalOpen, toggleBulkCopyPasteModal] = useState(useCopyPasteModal);
    const params: any = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    let avocado = { avocado_id: null, defects: [] };
    if (params.avocado_id > 0) {
        avocado = check.avocados.find((i) => i.avocado_id === parseInt(params.avocado_id, 10)) || { avocado_id: null, defects: [] };
    } else if (check.avocados && check.avocados.length > 0) {
        avocado = check.avocados[check.avocados.length - 1];
    }

    const addFruit = (n = 1) => dispatch(createManualAvocado({ test_id: check.test_id, n, unit_of_account: checkLocationUnit }) as any).then((response) => {
        if (!response.error) {
            dispatch(resetAvocadoState());
            if (n > 1) {
                dispatch(triggerCheckBusinessRules(check.test_id) as any);
            }
        }
    });

    const duplicateFruit = () => {
        if (check.avocados.length === 0 || !avocado?.avocado_id) {
            return;
        }

        dispatch(createDuplicateAvocado(avocado.avocado_id) as any).then((response) => {
            if (!response.error) {
                dispatch(resetAvocadoState());
            }
        });
    };

    // create a new fruit if there is no fruit yet
    useEffectOnce(() => {
        if (avocado.avocado_id === null && check.avocados.length === 0 && !fruitLoading) {
            addFruit(check?.use_bulk_edit || 1);
        }
    });

    // navigate to latest fruit
    useEffect(() => {
        if (check.avocados.length > 0) {
            const { avocado_id } = check.avocados[check.avocados.length - 1];
            navigate(`/layer/${check.layer_id}/add-check/${check.test_id}/${path_manual_fruit_form}/${avocado_id}`);
        }
    }, [check.avocados.length]);

    const buttons = <ManualNavButtons
        addFruit={addFruit}
        duplicateFruit={duplicateFruit}
        avocado={avocado}
        useGrid={useGrid}
        useCopyPasteModal={useCopyPasteModal}
        toggleBulkCopyPasteModal={toggleBulkCopyPasteModal} />;
    return (
        <Box hideButtons={isLoading} buttons={buttons}>
            {isLoading && <Loading></Loading>}
            {!isLoading && !useGrid && <div className="pt-3"><ManualForm grid={false} avocado={avocado} /></div>}
            {!isLoading && useGrid && <div className="pt-3">
                {check.avocados.map((a) => <div key={a.avocado_id}>
                    <ManualForm avocado={a} grid={true} />
                </div>)}
            </div>}
            {!isLoading && useGrid && useCopyPasteModal && <BulkCopyPasteFormModal isOpen={bulkCopyPasteModalOpen} field={location?.bulk_input_modal} toggleModal={toggleBulkCopyPasteModal} />}
        </Box >

    );
}

export function ManualNavButtons({ avocado, addFruit, useGrid, useCopyPasteModal, toggleBulkCopyPasteModal, duplicateFruit }) {
    const check = useSelector((state: any) => state.checks.current);
    const layer = useSelector((state: any) => state.layers.current);
    const isUpdating = useSelector((state: any) => state.avocados.isUpdating);
    const params = useParams();

    const navigate = useNavigate();
    const [isInvalidModalOpen, toggleInvalidModal] = useState(false);
    const config = useConfig();
    const the_summary_path = isArtLocation(check.location) ? path_advance_ripening : path_summary;
    const prev = config.prev_check_page(layer, check, params.action);
    const location = config.get_location(layer, check);
    const fields = location.fruit_form as FormField[];

    const onSummaryButtonClick = () => {
        if (location.fruit_form && !metaFormIsValid(fields, avocado, true)) {
            return;
        }
        navigate(`/layer/${check.layer_id}/add-check/${check.test_id}/${the_summary_path}`);
    };

    const onAddFruitButtonClick = () => {
        if (location.fruit_form && !metaFormIsValid(fields, avocado, true)) {
            return null;
        }
        addFruit();
        return null;
    };

    return (
        <div className="py-4 d-md-flex justify-content-end align-items-center">
            {check.avocados.length > 0 && !useGrid && <div className="me-auto ">
                {avocado.avocado_id > 0 && <span css={css`border-right: 1px solid #ddd;`} className="me-3 pe-3 d-inline-block">
                    <Button color="danger" className="my-1" outline={true} onClick={() => toggleInvalidModal(true)}><FontAwesomeIcon icon={faTrash} /> </Button>
                </span>
                }
                <InvalidFruitModal isOpen={isInvalidModalOpen} index={check.avocados.findIndex((i) => i.avocado_id === avocado.avocado_id)} toggleModal={toggleInvalidModal} avocado={avocado} />
                {check.avocados.map((a, index) => <Button key={a.avocado_id} color={avocado.avocado_id === a.avocado_id ? "primary" : "secondary"} className="me-2 my-1" onClick={() => navigate(`/layer/${check.layer_id}/add-check/${check.test_id}/${path_manual_fruit_form}/${a.avocado_id}`)} >{index + 1}</Button>)}
            </div>
            }
            {prev
                && <div className="pb-2 pb-sm-0 me-2 d-inline-flex d-md-block">
                    <Button color="secondary" outline={true} disabled={isUpdating} onClick={() => navigate(`/layer/${check.layer_id}/add-check/${check.test_id}/${prev.path}`)}>Back</Button>
                </div>
            }
            {/* <div className=" me-2 d-inline-flex d-md-block">
                {useGrid && <Button color="light" className="text-nowrap my-1" disabled={isUpdating} onClick={() => setUseGrid(false)}> <FontAwesomeIcon icon={faGripHorizontal} />  </Button>
                }
                {!useGrid && <Button color="light" className="text-nowrap my-1" disabled={isUpdating} onClick={() => setUseGrid(true)}> <FontAwesomeIcon icon={faGripVertical} />  </Button>
                }
            </div> */}
            {useCopyPasteModal && <div className=" me-2 d-inline-flex d-md-block">
                <Button color="light" className="text-nowrap my-1" disabled={isUpdating} onClick={() => toggleBulkCopyPasteModal(true)}> <FontAwesomeIcon icon={faPaste} />  </Button>
            </div>}
            {location.enable_duplicate_fruit && <div className=" me-2 d-inline-flex d-md-block">
                <Button color="light" className="text-nowrap my-1" disabled={isUpdating || check.avocados.length === 0} onClick={() => duplicateFruit()}> <FontAwesomeIcon icon={faCopy} /> Duplicate {location?.unit_of_account || "Fruit"} </Button>
            </div>}
            <div className=" me-2 d-inline-flex d-md-block">
                <Button color="primary" className="text-nowrap my-1" disabled={isUpdating} onClick={onAddFruitButtonClick}>Add {location?.unit_of_account || "Fruit"}</Button>
            </div>
            <div className=" d-inline-flex d-md-block">
                <Button color="light" className="text-nowrap my-1" disabled={isUpdating} onClick={onSummaryButtonClick}>Summary</Button>
            </div>
        </div>
    );
}
ManualNavButtons.propTypes = {
    avocado: PropTypes.object,
    addFruit: PropTypes.func,
};

export function ManualForm({ avocado, grid = false }) {
    const config = useConfig();
    const check = useSelector((state: any) => state.checks.current);
    const layer = useSelector((state: any) => state.layers.current);
    const checkLoading = useSelector((state: any) => state.checks.isLoading);
    const layerLoading = useSelector((state: any) => state.layers.isLoading);
    const location = config.get_location(layer, check);

    const fields = location.fruit_form;

    if (checkLoading || layerLoading) return <Loading />;

    // * fall back to form defined with legacy fields (only path as option)
    if (!fields || fields.length === 0) {
        return <DeprecatedManualForm avocado={avocado} />;
    }

    if (!(avocado.avocado_id > 0)) {
        return null;
    }

    return <FruitMetaForm avocado={avocado} fields={fields} grid={grid} />;
}


function FruitMetaForm({ avocado, fields, grid }: any) {
    const config = useConfig();
    const check = useSelector((state: any) => state.checks.current);
    const layer = useSelector((state: any) => state.layers.current);

    const location = config.get_location(layer, check);
    const patchAvocado = usePatchAvocado();
    const dispatch = useDispatch();
    const allDefects = useDefectsHook(layer.fruit_type);

    const activeDefects: any[] = (avocado?.defects || []).filter((i) => i.frequency > 0).map((i) => ({ ...i, value: i.defect_id }));

    // * First filter the form for defect groups, so that we can define setValue and onDebounce functions
    const filterFormRound1 = (field, index) => {
        if (field.name !== "defects") {
            return field;
        }

        const options = doFilterDefects({
            defect_severity: field.defect_severity,
            defect_position: field.defect_position,
        }, allDefects || []);

        return {
            ...field,
            return_objects: true,
            type: "checkbox",
            multi: true,
            name: `defect_group_${index}`,
            options
        };

    };

    // * adjusts the fields for usage with defects so that the correct options are rendered
    // * By splitting al defect group in seperate fields
    const formFieldsAdjustedForDefects = fields.map(filterFormRound1);


    // * update the local/global state of the fruit
    const setValue = useCallback((fieldName, value) => {

        // * global states stores one list of defects
        if (fieldName.startsWith("defect_group_")) {
            const field = formFieldsAdjustedForDefects.find((i) => i.name === fieldName);
            const group_defect_ids = field.options.map((i) => i.value);
            fieldName = "defects";
            // * remove all defects that are in this radio button group
            const activeDefectsWithoutTheseGroupDefects = activeDefects.filter((d) => !group_defect_ids.includes(d.defect_id));
            // * then only add the ones that are selected
            value = [...activeDefectsWithoutTheseGroupDefects, ...value.map((d) => ({ ...d, frequency: 1, defect_id: d.value }))];
        }

        patchAvocado(avocado, fieldName, value, false);

    }, [activeDefects]);

    // * make the api call to update the fruit
    const onDebounce = (fieldName, value) => {
        if (fieldName.startsWith("defect_group_")) {
            const field = formFieldsAdjustedForDefects.find((i) => i.name === fieldName);
            dispatch(registerDefects({ fruit_id: avocado.avocado_id, test_id: avocado.test_id, defect_ids: value.map((i) => i.value) as string[], positions: field.defect_position, severities: field.defect_severity }) as any);
            return;
        }
        patchAvocado(avocado, fieldName, value, true);
    };


    // * split the avocado defects list into seperate groups
    const object = formFieldsAdjustedForDefects
        .filter((field) => field.name.startsWith("defect_group_"))
        .reduce((acc, field) => ({ ...acc, [field.name]: activeDefects.filter((d) => field.options.map((i) => i.defect_id).includes(d.defect_id)) }), avocado);

    // * Filter the fields again, to enable the "defect_severity_on_one_line" option
    // * This filter happens as last because the `object`, `setValue` and `onDebounce` functions are needed
    const filterFormRound2 = (field) => {
        // *  No alteration needed ?
        if (!field.name.startsWith("defect_group_") || !field.defect_severity_on_one_line) {
            return field;
        }

        // * Set all options of the group per defect, but hide them by default
        const allOptionsSetHidden = field.options.map((i) => ({ ...i, hidden: true }));

        // * group the defects by defect_id
        const defects_grouped_by_defect = field.options.reduce((acc, i) => {
            // * value is formatted as "{postion}_{severity}_{defect_id}"
            const value = i.value.split("_").at(-1);

            // * Search for this defect and set the label to the severity and unhide the option
            const filterOption = (x) => (x.defect_id === i.defect_id ? ({ ...x, label: x.severity, hidden: false }) : x);

            if (acc[value]) {
                return { ...acc, [value]: { ...acc[value], options: acc[value].options.map(filterOption) } };
            }
            return { ...acc,
                [value]: {
                    ...i,
                    return_objects: true,
                    description: null,
                    type: "checkbox",
                    multi: true,
                    name: field.name, // * the name of the field is the same for all the grouped defects
                    label: i.label,
                    options: allOptionsSetHidden.map(filterOption) } };
        }, {});

        const headerItem = {
            type: "header",
            label: field.label,
        };

        return {
            ...field,
            type: "element",
            el: <MetaForm
                setValue={setValue}
                onDebounce={onDebounce}
                debounce_time={500}
                grid={grid}
                config={config}
                meta={[headerItem, ...Object.values(defects_grouped_by_defect)]}
                object={object}
                extra_context={{ fruit_variety: layer.fruit_variety || undefined, fruit_type: layer.fruit_type || undefined }} />
        };


    };

    const formFieldsAdjustedForDefects2 = formFieldsAdjustedForDefects.map(filterFormRound2);

    let wrapperClass = "";
    if (grid) {
        // * if we display the fruit form in grid format (multiple fruit), then we add column with the fruit number
        const index = check.avocados.findIndex((i) => i.avocado_id === avocado.avocado_id);

        fields = [{
            type: "element",
            lg: "1",
            el: <FormGroup>
                <Label>{location?.unit_of_account || "Fruit"}</Label>
                <div>{index + 1}</div>
            </FormGroup>
        }, ...fields];
        wrapperClass = index !== 0 ? "border-top pt-3" : "";

    }


    return <div className={wrapperClass}>
        <MetaForm
            setValue={setValue}
            onDebounce={onDebounce}
            debounce_time={500}
            grid={grid}
            config={config}
            meta={formFieldsAdjustedForDefects2}
            object={object}
            extra_context={{ fruit_variety: layer.fruit_variety || undefined, fruit_type: layer.fruit_type || undefined }} />
    </div>;
}

function convertToFloat(value: string) {
    // Replace any non-numeric characters (including letters)
    let cleanedCell = value.replace(/[^\d,.-]/g, "");
    // Replace commas with periods
    cleanedCell = value.replace(",", ".");
    // Convert to float, if not empty
    return cleanedCell ? parseFloat(cleanedCell) : cleanedCell;
}
function BulkCopyPasteFormModal({ isOpen, toggleModal, field }) {
    const check = useSelector((state: any) => state.checks.current);
    const [value, setValue] = useState("");

    const patchAvocado = usePatchAvocado();
    const onPaste = async () => {
        const data = value.split("\n").map((i) => i.split("\t"));
        await Promise.all(
            // * loop over all the known fruit, check if there is corresponding data in the pasted excel data
            check.avocados.flatMap((i, index) => {
                const columns = data[index];

                if (!isFilledArray(columns)) {
                    return [];
                }

                // * when there are more then 1 column and field = pressure then copy 2 fta
                if (columns.length >= 2 && field === "2_pressures") {
                    return [
                        patchAvocado(i, "pressure_1", convertToFloat(columns[0])),
                        patchAvocado(i, "pressure_2", convertToFloat(columns[1])),
                    ];
                }

                return [patchAvocado(i, field, convertToFloat(columns[0]))];
            })

        );
        toggleModal(false);
    };

    return <div>
        <Modal isOpen={isOpen} toggle={() => toggleModal(false)}>
            <ModalHeader toggle={() => toggleModal(false)}>Paste Bulk Data</ModalHeader>
            <ModalBody>
                <FormGroup>
                    <Label for="copyFromExcel">Paste data here</Label>
                    <Input type="textarea" value={value} onChange={(e) => setValue(e.target.value)} name="text" id="copyFromExcel" />
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={() => onPaste()}>Paste</Button>
                <Button color="secondary" onClick={() => toggleModal(false)}>Cancel</Button>
            </ModalFooter>
        </Modal>
    </div>;
}
