import { AvosAction } from "../../types";
import { LIST, SELECT } from "./actionTypes";
import { Tenant } from "./reducer";

const endpoint = "/supplier-portal";

export const getTenants = () => {
    return {
        types: [LIST.LOADING, LIST.SUCCESS, LIST.ERROR],
        payload: {
            request: {
                method: "GET",
                url: `${endpoint}/tenant`,
            },
        },
    } as unknown as AvosAction;
};

export const selectTenant = (tenant:Tenant) => ({
    type: SELECT.SUCCESS,
    payload: {
        data: tenant,
    },
}) as AvosAction;
