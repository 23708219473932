/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useSelector } from "react-redux";
import { Table } from "reactstrap";
import NumericInputField from "../../../Forms/NumericInputField";
import { Loading } from "../../../Helper/Loading";
import usePatchAvocado from "../usePatchAvocado";
import { AvocadoIndex } from "./Images";
import Box from "./Layout";

export default function BrixInput() {
    const check = useSelector((state) => state.checks.current);
    const isLoading = useSelector((state) => state.checks.isLoading);
    const layer = useSelector((state) => state.layers.current);
    const patchAvocado = usePatchAvocado();

    // either both pressures are filled, or both pressures are empty
    // const canSubmit = check.avocados.filter(i => i.weight > 0).length === check.avocados.length
    return (
        <Box headerTitle={""} hideButtons={isLoading}>
            {isLoading && <Loading></Loading>}
            {!isLoading
                && <div css={check.location === "validation" ? css`max-width: 64rem;` : css`max-width: 40rem;`}>
                    <Table size="sm" responsive={true} >
                        <thead>
                            <tr className="align-middle">
                                <th className="text-muted text-uppercase text-start ">{layer.fruit_type}</th>
                                <th className="text-muted text-uppercase text-start ">BRIX</th>
                            </tr>
                        </thead>
                        <tbody>
                            {check.avocados.map((i, index) => (
                                <tr key={i.avocado_id} className="align-items-center pb-2">
                                    <td className="text-start text-uppercase align-middle" ><AvocadoIndex avocado={i} key={i.avocado_id} index={index + 1}></AvocadoIndex></td>
                                    <td className="text-start align-middle" css={css`width: 10rem;`}>
                                        <NumericInputField
                                            field="manual_brix"
                                            db_value={i.manual_brix}
                                            unity="%"
                                            setValue={(manual_brix) => patchAvocado(i, "manual_brix", manual_brix)}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            }
        </Box>

    );
}
