/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import {
    Table
} from "reactstrap";
import useConfig from "../../../../actions/Tenants/config/configHook";
import NumericInputField from "../../../Forms/NumericInputField";
import { Loading } from "../../../Helper/Loading";
import usePatchAvocado from "../usePatchAvocado";
import { AvocadoIndex } from "./Images";
import Box from "./Layout";

export default function BulkFTAInput() {
    const config = useConfig();
    const check = useSelector((state) => state.checks.current);
    const user = useSelector((state) => state.auth.user);
    const isLoading = useSelector((state) => state.checks.isLoading);
    const isUpdating = useSelector((state) => state.avocados.isUpdating);
    const layer = useSelector((state) => state.layers.current);
    const patchAvocado = usePatchAvocado();

    // either both pressures are filled, or both pressures are empty
    const canSubmit = check.avocados.filter((i) => {
        const status = get_fta_status(user.tenant.has_two_fta, i);
        // warning is never allowed to continue
        if (status === "warning") {
            return false;
        }
        // leave pressure empty
        // was first only allowed if you have a linear model
        if (status === "muted") {
            return true;
        }

        // we are gooooooood
        return status === "success";
    }).length === check.avocados.length; // if all avocados statisfy these conditions then you can submit

    return (
        <Box headerTitle={""} canSubmit={canSubmit && !isUpdating} >
            {isLoading && <Loading></Loading>}
            {!isLoading
                && <div css={check.location === "validation" ? css`max-width: 64rem;` : css`max-width: 40rem;`}>
                    <Table size="sm" responsive={true} borderless={true}>
                        <thead>
                            <tr className="align-middle">
                                <th className="text-muted text-uppercase text-start ">{layer.fruit_type}</th>
                                {user.tenant.has_two_fta && <th className="text-muted text-uppercase text-start ">{config.lang.pressure}1</th>}
                                {user.tenant.has_two_fta && <th className="text-muted text-uppercase text-start ">{config.lang.pressure}2</th>}
                                {!user.tenant.has_two_fta && <th className="text-muted text-uppercase text-start ">{config.lang.pressure}</th>}
                            </tr>
                        </thead>
                        <tbody>
                            {check.avocados.map((i, index) => {
                                return (
                                    <tr key={i.avocado_id} className="align-items-center pb-2">
                                        <td className="text-start text-uppercase align-middle" ><AvocadoIndex avocado={i} key={i.avocado_id} index={index + 1}></AvocadoIndex></td>
                                        {!user.tenant.has_two_fta && <td >
                                            <NumericInputField
                                                field="pressure"
                                                db_value={i.pressure}
                                                min={0}
                                                max={100}
                                                unity={user.tenant.uses_kg ? "KG" : "LBS"}
                                                setValue={(_, value) => patchAvocado(i, "pressure", value)}
                                            />
                                        </td>}
                                        {user.tenant.has_two_fta && <td >
                                            <NumericInputField
                                                field="pressure_1"
                                                db_value={i.pressure_1}
                                                min={0}
                                                max={100}
                                                unity={user.tenant.uses_kg ? "KG" : "LBS"}
                                                setValue={(_, value) => patchAvocado(i, "pressure_1", value)}
                                            />
                                        </td>}
                                        {user.tenant.has_two_fta && <td >
                                            <NumericInputField
                                                field="pressure_2"
                                                db_value={i.pressure_2}
                                                min={0}
                                                max={100}
                                                unity={user.tenant.uses_kg ? "KG" : "LBS"}
                                                setValue={(_, value) => patchAvocado(i, "pressure_2", value)}
                                            />
                                        </td>}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                </div>
            }
        </Box>

    );
}

const isValidNumber = (number) => (number > 0 || parseFloat(number) === 0) && number !== "" && number !== null && number !== undefined;
const get_fta_status = (has_two_fta, avocado) => {
    if (!has_two_fta) {
        if (isValidNumber(avocado.pressure) && avocado.pressure >= 0) {
            return "success";
        }
        return "muted";
    }

    const firstValueValidNumber = isValidNumber(avocado.pressure_1);
    const secondValueValidNumber = isValidNumber(avocado.pressure_2);
    const bothValidNumbers = firstValueValidNumber && secondValueValidNumber;
    const bothInValidNumbers = !firstValueValidNumber && !secondValueValidNumber;

    if (bothInValidNumbers) {
        return "muted";
    }

    if (!bothValidNumbers) {
        return "warning";
    }
    return "success";
};
export function PressureIcon({ avocado }) {
    const user = useSelector((state) => state.auth.user);
    const warningTemplate = <td css={css`width: 3rem;`} className="align-middle text-warning"> <div>
        <FontAwesomeIcon icon={["far", "exclamation-triangle"]} />
    </div> </td>;
    const greenTemplate = <td css={css`width: 3rem;`} className="align-middle text-success"> <div>
        <FontAwesomeIcon icon={["far", "check"]} />
    </div> </td>;
    const mutedTemplate = <td css={css`width: 3rem;`} className="align-middle text-muted"> <div>
        <FontAwesomeIcon icon={["far", "check"]} />
    </div> </td>;
    const status = get_fta_status(user.tenant.has_two_fta, avocado);
    if (status === "muted") {
        return mutedTemplate;
    }

    if (status === "warning") {
        return warningTemplate;
    }
    return greenTemplate;
}
PressureIcon.propTypes = {
    avocado: PropTypes.object,
};

export function RequiredIcon({ status }) {
    const dangerTemplate = <td css={css`width: 3rem;`} className="align-middle text-danger"> <div>
        <FontAwesomeIcon icon={["far", "exclamation-triangle"]} />
    </div> </td>;
    const successTemplate = <td css={css`width: 3rem;`} className="align-middle text-success"> <div>
        <FontAwesomeIcon icon={["far", "check"]} />
    </div> </td>;

    if (status) {
        return successTemplate;
    }

    return dangerTemplate;
}
RequiredIcon.propTypes = {
    status: PropTypes.bool
};
export function QuickPressureInput({ avocado }) {
    const user = useSelector((state) => state.auth.user);
    const patchAvocado = usePatchAvocado();
    return (<table>
        <tbody>
            <tr>
                {!user.tenant.has_two_fta && <td >
                    <NumericInputField
                        field="pressure"
                        db_value={avocado.pressure}
                        min={0}
                        max={100}
                        unity={user.tenant.uses_kg ? "KG" : "LBS"}
                        setValue={(_, value) => patchAvocado(avocado, "pressure", value)}
                    />
                </td>}
                {user.tenant.has_two_fta && <td >
                    <NumericInputField
                        field="pressure_1"
                        db_value={avocado.pressure_1}
                        min={0}
                        max={100}
                        unity={user.tenant.uses_kg ? "KG" : "LBS"}
                        setValue={(_, value) => patchAvocado(avocado, "pressure_1", value)}
                    />
                </td>}
                {user.tenant.has_two_fta && <td >
                    <NumericInputField
                        field="pressure_2"
                        db_value={avocado.pressure_2}
                        min={0}
                        max={100}
                        unity={user.tenant.uses_kg ? "KG" : "LBS"}
                        setValue={(_, value) => patchAvocado(avocado, "pressure_2", value)}
                    />
                </td>}
            </tr>
        </tbody>
    </table>);
}
QuickPressureInput.propTypes = {
    avocado: PropTypes.object,
};
