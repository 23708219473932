import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
    Button,
    Modal, ModalBody, ModalFooter, ModalHeader
} from "reactstrap";
import { createCheck } from "../../../../actions/Checks/actions";
import { addLayerChild, addLayerLocationHistory, createSubLayer, getLayerChecks } from "../../../../actions/Layers/actions";

import { isArtLocation } from "../../../../actions/Layers/constants";
import useConfig from "../../../../actions/Tenants/config/configHook";
import { DeviceResultActions } from "../../../../actions/Tenants/config/constants";
import { isFilledArray } from "../../../../utils";
import MetaForm, { metaFormIsValid } from "../../../Forms/MetaForm";
import { defaultLayerHook } from "../FindLayerBox";
import { SPECIAL_ACTIONS } from "./SpecialActions";

export default function CreateChildAndStartCheck({ allocate_stage, child_type, check_location }) {
    const layer = useSelector((state) => state.layers.current);
    const config = useConfig();
    const dispatch = useDispatch();
    const params = useParams();
    const navigate = useNavigate();
    // * Only intrested in the default state here
    const defaultLayer = defaultLayerHook()[0];
    const layerIsUpdating = useSelector((state) => state.layers.isUpdating);
    const checkIsUpdating = useSelector((state) => state.checks.isUpdating);
    const locationHistoryLoading = useSelector((state) => state.layers.locationHistoryLoading);
    const [modalOpen, setModal] = useState(params.action === SPECIAL_ACTIONS.CREATE_CHILD_AND_START_CHECK);
    const [createCheckData, setCheck] = useState({});


    // *inherit fruit type and other attributes from parent
    const defaultSubLayer = {
        ...defaultLayer,
        fruit_type: layer.fruit_type ? layer.fruit_type : defaultLayer.fruit_type,
        fruit_variety: layer.fruit_variety ? layer.fruit_variety : defaultLayer.fruit_variety,
        type: child_type,
        supplier: layer.supplier || "",
        country: layer.country || "",
    };

    const [subLayerState, setSubLayer] = useState(defaultSubLayer);

    // * Update child, if one of the parent attributes or default values changes
    useEffect(
        () => setSubLayer(defaultSubLayer),
        [defaultSubLayer.fruit_type, defaultSubLayer.fruit_variety, defaultSubLayer.supplier, defaultSubLayer.country]
    );

    const location_config = config.get_location(subLayerState, { location: check_location });
    const shouldShowModal = isFilledArray(location_config?.meta_form_layer) || isFilledArray(location_config?.pre_check_form);

    const _createLayerAndStartCheck = () => {
        //  We are already doing something
        if (layerIsUpdating || checkIsUpdating || locationHistoryLoading) {
            return;
        }

        if (location_config.meta_form_layer && !metaFormIsValid(location_config.meta_form_layer, subLayerState)) {
            return;
        }

        if (location_config.pre_check_form && !metaFormIsValid(location_config.pre_check_form, createCheckData)) {
            return;
        }


        // allocate parent to stage
        if (allocate_stage && allocate_stage !== layer.latest_location?.stage) {
            dispatch(addLayerLocationHistory({ layer_id: layer.id, stage: allocate_stage }));
        }

        dispatch(createSubLayer({ ...subLayerState })).then((response) => {
            if (!response.error) {
                const theSubLayer = response.payload.data;

                // also allocate the new layer to this location
                dispatch(addLayerLocationHistory({ layer_id: theSubLayer.id, stage: allocate_stage }));
                dispatch(addLayerChild(layer.id, theSubLayer.id,));

                const test = {
                    layer_id: theSubLayer.id,
                    location: location_config.value,
                };

                if (location_config.is_manual) {
                    test.is_manual = true;
                }
                // * Set the requested_calibration_stage,
                // * needed to fetch and filter calibration fruit
                const { pressure_calibration_settings } = location_config;
                const requested_calibration_stage = pressure_calibration_settings?.calibration_stage || false;
                if (requested_calibration_stage) {
                    test.requested_calibration_stage = requested_calibration_stage;
                }
                const postCheck = { ...test, ...createCheckData };

                if (isArtLocation(location_config.value)) {
                    dispatch(getLayerChecks(theSubLayer.id, { check_location: "advance-ripening" })).then((response) => {
                        if (!response.error) {
                            // if it is advance-ripening and there is a previous advance_ripening check, go to that check
                            const checks = response.payload.data;
                            const prevCheck = checks.sort((a, b) => b.test_id - a.test_id).find((i) => i.location === location_config.value);
                            if (prevCheck) {
                                navigate(`/layer/${theSubLayer.id}/add-check/${prevCheck.test_id}/advance-ripening`);
                            } else {
                                // if there is no previous advance_ripening check, create a new one
                                createAndStartCheck(postCheck, theSubLayer);
                            }
                        }
                    });
                } else {
                    createAndStartCheck(postCheck, theSubLayer);
                }
            }
        });
    };

    const createAndStartCheck = (postCheck, theSubLayer) => {

        dispatch(createCheck(postCheck)).then((response) => {
            if (!response.error) {

                // * This is a feature, not a bug. If no flow is defined we just close the modal.
                // * This way you can setup small checks to just ask small bits of info with pre_check_form
                if (location_config.flow.length === 0) {
                    setModal(false);
                    return;
                }
                navigate(`/layer/${theSubLayer.id}/add-check/${response.payload.data.test_id}/${location_config.flow[0]}`);
            }
        });
    };


    const isDisabled = () => {
        // We need to use calibration... but calibration is missing
        if (location_config.device_result_action === DeviceResultActions.pressure_advice && !config.is_location_calibrated(location_config, layer)) {
            return true;
        }
        // We need to setup calibration... but is is already calibrated
        if (location_config.device_result_action === DeviceResultActions.setup_pressure_calibration && config.is_location_calibrated(location_config, layer)) {
            return true;
        }

        if (layerIsUpdating || checkIsUpdating || locationHistoryLoading) {
            return true;
        }

        return false;
    };

    const startCheck = () => {
        // only show modal if extra meta is required on this location
        if (shouldShowModal) {
            setModal(true);
            return;
        }
        _createLayerAndStartCheck();
    };
    const submit = () => {
        _createLayerAndStartCheck();
    };

    if (!location_config || location_config.is_invalid) {
        return null;
    }
    return (<div>
        <Button className="my-1 text-nowrap" color="primary" disabled={isDisabled()} onClick={() => startCheck()}>{location_config.text} check</Button>
        <Modal isOpen={modalOpen} size={"md"} toggle={() => setModal(false)}>
            <ModalHeader toggle={() => setModal(false)} >{`${location_config.text + isArtLocation(location_config.value) ? "" : ` check`}`}</ModalHeader>
            <ModalBody>
                <div>
                    {location_config.meta_form_layer && <MetaForm
                        key="layer"
                        meta={location_config.meta_form_layer}
                        setValue={(field, value) => setSubLayer((object) => ({ ...object, [field]: value }))}
                        object={subLayerState}
                        config={config} />}
                    {location_config.pre_check_form && <MetaForm
                        key="check"
                        meta={location_config.pre_check_form}
                        setValue={(field, value) => setCheck((object) => ({ ...object, [field]: value })) }
                        object={createCheckData}
                        extra_context={{ fruit_variety: subLayerState.fruit_variety, fruit_type: subLayerState.fruit_type }}
                        config={config} />}

                </div>
            </ModalBody>
            <ModalFooter>
                <Button color="light" onClick={() => setModal(false)}>Cancel </Button>
                <Button disabled={isDisabled()} color="success" onClick={() => submit()}>Start</Button>
            </ModalFooter>
        </Modal >
    </div>);
}

CreateChildAndStartCheck.propTypes = {
    allocate_stage: PropTypes.string,
    child_type: PropTypes.string,
    check_location: PropTypes.string,
};
