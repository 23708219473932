import { defineAction } from "redux-define";
import {
    ERROR,
    FILTER,
    LOADING,
    SUCCESS,
    TheApp,
} from "../../../constants/stateConstants";

export const report = defineAction("SUPPLIER_PORTAL_TENANTS", [LOADING, ERROR, SUCCESS], TheApp);
export const LIST = defineAction("LIST", [FILTER, LOADING, ERROR, SUCCESS], report);
export const SELECT = defineAction("SELECT", [SUCCESS], report);
