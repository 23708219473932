import React from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Button } from "reactstrap";

const UserNotAuthorized: React.FC = () => {
    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1); // Navigate to the previous page
    };

    return (
        <Container className="d-flex flex-column justify-content-center align-items-center" style={{ height: "100vh", textAlign: "center" }}>
            <Row>
                <Col>
                    <h1 style={{ fontSize: "2.0rem", color: "#dc3545", marginBottom: "1rem" }}>403 - Not Authorized</h1>
                    <p style={{ fontSize: "1.25rem", color: "#6c757d", marginBottom: "2rem" }}>
            You do not have the necessary permissions to view this page.
                    </p>
                    <div className="d-flex justify-content-center gap-2">
                        <Button color="primary" onClick={handleGoBack}>
              Go Back
                        </Button>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default UserNotAuthorized;
