/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { faTrash } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "reactstrap";
import useConfig from "../../../../actions/Tenants/config/configHook";
import { TriggerDeviceButton } from "../../../Devices/TriggerDeviceButton";
import { InvalidFruitModal } from "./InvalidFruitModal";

export function DeviceResultsNavButtons({ avocado, action }) {
    const config = useConfig();
    const user = useSelector((state) => state.auth.user);
    const layer = useSelector((state) => state.layers.current);
    const check = useSelector((state) => state.checks.current);
    const checkIsUpdating = useSelector((state) => state.checks.isUpdating);
    const avocadoIsUpdating = useSelector((state) => state.avocados.isUpdating);
    const avocadoIsLoading = useSelector((state) => state.avocados.isLoading);
    const navigate = useNavigate();
    const params = useParams();
    const next = config.next_check_page(layer, check, params.action);
    const prev = config.prev_check_page(layer, check, params.action);
    const [isInvalidModalOpen, toggleInvalidModal] = useState(false);
    const canNotContinue = check.avocados.length === 0 || avocadoIsUpdating || avocadoIsLoading || checkIsUpdating;

    // if avocado is removed/invalidated navigate to latest fruit
    useEffect(() => {
        if (avocado.avocado_id === null && check.avocados.length > 0) {
            const { avocado_id } = check.avocados[check.avocados.length - 1];
            navigate(`/layer/${check.layer_id}/add-check/${check.test_id}/${action}/${avocado_id}`);
        }
    }, [avocado.avocado_id, check.avocados.length]);

    return (
        <div className="py-4 d-md-flex justify-content-end align-items-center">
            {check.avocados.length > 0
                && <div className="me-auto pe-2">
                    {avocado.avocado_id > 0 && <span css={css`border-right: 1px solid #ddd;`} className="me-3 pe-3 d-inline-block">
                        <Button color="danger" className="py-1 my-1" outline={true} onClick={() => toggleInvalidModal(true)}><FontAwesomeIcon icon={faTrash} /> </Button>
                    </span>
                    }
                    <InvalidFruitModal isOpen={isInvalidModalOpen} index={check.avocados.findIndex((i) => i.avocado_id === avocado.avocado_id)} toggleModal={toggleInvalidModal} avocado={avocado} />
                    {check.avocados.map((a, index) => (
                        <Button key={a.avocado_id}
                            color={avocado.avocado_id === a.avocado_id ? "primary" : "secondary"}
                            className="me-2 py-1 my-1"
                            onClick={ () => navigate(`/layer/${check.layer_id}/add-check/${check.test_id}/${action}/${a.avocado_id}`)}>{index + 1}
                        </Button>
                    ))}
                    { (config.strict_check_size && config.need_setup_pressure_ratio) && [...Array(Math.max(user.tenant.quality_check_size - check.avocados.length, 0)).keys()].map((index) => <Button key={index} color="secondary" css={css`opacity: 0.3!important;`} disabled className="me-2 py-1 my-1" outline={true} >{check.avocados.length + index + 1}</Button>)}
                </div>
            }
            {prev
                && <div className="pb-2 pb-sm-0 me-2 d-inline-flex d-md-block">
                    <Button color="secondary" outline={true} disabled={canNotContinue} onClick={() => navigate(`/layer/${check.layer_id}/add-check/${check.test_id}/${prev.path}`)}>Back</Button>
                </div>
            }
            <div className="me-2 d-inline-flex d-md-block">
                <div className="my-1">
                    <TriggerDeviceButton />
                </div>
            </div>
            {!next
                && <div className="me-2 d-inline-flex d-md-block">
                    <Button color="light" className="text-nowrap my-1" disabled={canNotContinue} block onClick={() => navigate(`/layer/${check.layer_id}/add-check/${check.test_id}/${config.summary}`)} >Summary <span className="ms-1 badge badge-light">{check.avocados.length}</span></Button>
                </div>
            }
            {next
                && <div className="me-2 d-inline-flex d-md-block">
                    <Button color="light" className="text-nowrap my-1" disabled={canNotContinue} block onClick={() => navigate(`/layer/${check.layer_id}/add-check/${check.test_id}/${next.path}`)} >Complete Scanning</Button>
                </div>
            }

        </div>
    );
}
DeviceResultsNavButtons.propTypes = {
    avocado: PropTypes.object,
    action: PropTypes.string
};
