import { Filter } from "../../Admin/reducer";
import { AvosAction } from "../../types";
import { LIST } from "./actionTypes";

const endpoint = "/supplier-portal";

export const getReports = (tenantId: string, filter: Filter) => {
    return {
        types: [LIST.LOADING, LIST.SUCCESS, LIST.ERROR],
        payload: {
            request: {
                method: "GET",
                url: `${endpoint}/${tenantId}/report?limit=${filter.limit}&offset=${filter.offset}`,
                params: filter,
            },
        },
    } as unknown as AvosAction;
};

export const updateReportsFilter = (filter: Filter) => ({
    type: LIST.FILTER,
    payload: filter,
}) as AvosAction;
