import dayjs from "dayjs";
import { formatCheck2Frontend } from "../../../../../actions/Checks/util";
import { device_image_types } from "../../../AddItem/components/Images";
import webpToJpgBase64 from "../../../image-utils";

export const loadImage = async (logo_name) => {
    try {
        const logo_image = await import(`../../../../../img/${logo_name}`);
        return logo_image.default;
    } catch (error) {
        return null;
    }
};

export const getFlagColor = (flag, default_color = "#939496") => {
    if (flag === "success" || flag === "flag-success") {
        return "#198754";
    } if (flag === "warning" || flag === "flag-warning") {
        return "#ffc107";
    } if (flag === "danger" || flag === "flag-danger") {
        return "#dc3545";
    }
    // Default color if the flag doesn't match any of the above
    return default_color;
};

// Function to truncate a text
export const truncateText = (text, maxLength = 32, truncateLength = 30, truncationIndicator = "...") => {
    if (text.length > maxLength) {
        return `${text.substring(0, truncateLength)}${truncationIndicator}`;
    }
    return text;
};
export enum ReportType {
    Intake = "intake",
    ART = "advance-ripening",
    Reassessment = "reassessment",
    Production = "production_assessment",
    IntakeDates = "intake_dates",
    Storage = "storage",
    FinalCheck = "final_check",
}

export const prepareChecksForPDf = async (checks) => {
    const formatted_checks = await Promise.all(checks.map(async (i) => {
        const data = formatCheck2Frontend(i);
        const all_images = [
            ...(data.images ? data.images.map((x) => ({ ...x, label: data.label })) : []),
            ...(data.avocados ? data.avocados.flatMap((a) => a.images
                .filter((x) => !device_image_types.includes(x.type)))
                .filter((x) => x.url)
                .map((x) => ({ ...x, label: data.label })) : [])
        ];

        // Preload all images and replace URLs with Blob URLs
        const preloadedImages = await Promise.all(all_images.map(async (image) => ({
            ...image,
            url: await webpToJpgBase64(image.url)
        })));
        const createdDate = dayjs(i.created); // Invoke dayjs once
        const timestamp = createdDate.valueOf(); // Get timestamp in milliseconds
        const date = createdDate.format("YYYY-MM-DD"); // Format date as YYYY-MM-DD
        return { ...data, timestamp, date, all_images: preloadedImages.filter((i) => i.url) };
    }));
    return formatted_checks.sort((a, b) => a.timestamp - b.timestamp);

};
