/** @jsxImportSource @emotion/react */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { createLayer } from "../../../actions/Layers/actions";
import { LAYER_TYPE, Layer } from "../../../actions/Layers/constants";
import useConfig from "../../../actions/Tenants/config/configHook";
import mq from "../../../constants/mediaqueries";
import { getFilledArrayOrDefault } from "../../../utils";
import LayerAutoComplete from "../../Forms/LayerAutoComplete";
import { CreateLayerModal } from "./Layer/CreateLayer";

export function defaultLayerHook(): [Layer, React.Dispatch<React.SetStateAction<Layer>>] {
    const config = useConfig();
    const filter = useSelector<any, any>((state) => state.layers.filter);
    const [layer, setLayer] = useState<Layer>({
        label: "",
        type: LAYER_TYPE.PALLET
    } as any);

    // * Temporary disable fruit type detection by mini
    // const selected_mini = useSelector((state) => state.devices.selected_mini);
    // const fruitType = selected_mini && !config.can_do_manual_check ? selected_mini.fruit_type : config.default_fruit_type;

    // * Find default fruit_type from root_config
    const rootDefaultFruitType = config.root_config?.default_fruit_type;

    // * Find default_value from fruit_type in meta_form (Edit Layer Form)
    const layer_config = config.get_layer_config({ type: layer.type });
    const defaultFruitTypes = getFilledArrayOrDefault(layer_config?.meta_form).filter((x) => x.name === "fruit_type" && x.default_value).map((x) => x.default_value);

    // * Set default value from root_config, or edit_layer_form or from hardcoded config (Legacy feature, was for most set to avocado)
    const fruitType = rootDefaultFruitType || defaultFruitTypes?.[0] || config.default_fruit_type;

    const fruitConfig = config.get_fruit_type(fruitType);

    useEffect(() => {
        const defaultLayer: Layer = {
            fruit_type: fruitType,
            fruit_variety: fruitConfig.default_variety,
        } as Layer;
        setLayer((prev) => ({ ...prev, ...defaultLayer }));

    }, [fruitType, fruitConfig.default_variety]);

    useEffect(() => {
        const stage = config.get_stage(filter.location);

        // * if we explicitly set a default create layer type
        if (stage && stage?.default_create_layer_type) {
            setLayer((prev) => ({ ...prev, type: stage.default_create_layer_type }));
        // * else default to the layer type we are viewing
        } else if (filter.layer_type) {
            setLayer((prev) => ({ ...prev, type: filter.layer_type }));
        }

    }, [filter.layer_type, filter.location]);

    return [layer, setLayer];
}

export default function FindLayerBox() {
    const config = useConfig();
    const dispatch = useDispatch();
    const filter = useSelector<any, any>((state) => state.layers.filter);
    const [layer, setLayer] = defaultLayerHook();

    const [layerModalOpen, setLayerModal] = useState(false);
    const navigate = useNavigate();

    const layer_config = config.get_layer_config(layer);

    useEffect(() => {
        if (filter.layer_type) {
            setLayer((prev) => ({ ...prev, type: filter.layer_type }));
        }
    }, [filter.layer_type]);

    const _createLayer = () => {
        const layer_config = config.get_layer_config(layer);
        Promise.resolve(
            dispatch(createLayer(layer)),
        ).then((response) => {
            if (response.payload) {
                const layer = response.payload.data;
                navigate(`/layer/${layer.id}/action/${layer_config.start_action || "edit"}`);
            }
        });
    };

    const setValue = (field, value) => setLayer((prev) => ({ ...prev, [field]: value }));

    const onClose = () => setLayerModal(false);

    const setLayerSelect = (select) => {
        if (select.id) {
            navigate(`/layer/${select.id}`);
            return;
        }
        setLayer(select);
        setLayerModal(true);
    };

    if (!layer_config) {
        return null;
    }

    return <div>
        <CreateLayerModal
            isOpen={layerModalOpen ? { withScanner: false } : false}
            onClose={onClose}
            onSubmit={_createLayer}
            layer={layer}
            setLayerSelect={setLayerSelect}
            setValue={setValue}
            disableSaveButton={!layer.label || !layer.type}
        />
        <div className="d-flex align-items-center justify-content-between justify-content-lg-start">
            <div css={mq({ width: ["100%", "100%", "100%", "20rem"] })}>
                <LayerAutoComplete type={null} layer={layer} setLayer={setLayerSelect} label_format={layer_config.label_format} invalid_hint={layer_config.invalid_hint} />
            </div>
        </div>
    </div >;
}
