/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";
import { Button } from "reactstrap";

export interface RadioButtonProps {
    value: string | boolean,
    current: any,
    name: string,
    className: string,
    onChange: (name, value) => void,
    children: string | React.ReactElement,
    disabled: boolean,
    css: any,
    color: string,
}

export function RadioButton({
    value, current, name, color = "primary", className, onChange, children, disabled, css
}: RadioButtonProps) {
    return (
        <Button color={color} outline={current !== value} css={css} disabled={disabled} className={["btn-radio", className || ""].join(" ")} onClick={() => onChange(name, value)}>
            {children}
        </Button>
    );
}

export interface selectedValueProps {
    value?: string | boolean,
    label?: string
}

export interface RadioButtonsProps {
    options: { value: string | boolean, text?: string, label?: string, color?: string, className?: string, disabled?: boolean }[],
    current?: string,
    name: string,
    color?: string,
    onChange: (name, value) => void,
    className?: string,
    disabled?: boolean,
    default_value?: string,
}

export function RadioButtons({
    options, current, name, color = "primary", onChange, className = "", disabled = false, default_value
}: RadioButtonsProps) {

    let selectedValue: selectedValueProps = {};

    selectedValue = options.find((i) => String(i.value) === String(current || default_value)) || {};

    return <div className="d-flex flex-wrap">{options.map((i) => <div key={String(i.value)} css={css`flex: 0 0 auto; min-width: 5.5rem;`} className="pe-1 pb-1">
        <RadioButton
            color={i.color ? i.color : color}
            value={i.value}
            name={name}
            disabled={i.disabled || disabled}
            current={selectedValue.value}
            css={css`border-color: ${i.color}; background-color: ${i.color}; opacity: ${i.color && current !== i.value ? 0.8 : 1};&:hover, &:active, &:focus{background-color: ${i.color}; } `}
            className={`w-100 text-nowrap ${i.className} ${className}`}
            onChange={(name, value) => {
                const newValue = current === value ? undefined : value;
                if (newValue === undefined) (document.activeElement as any).blur();
                onChange(name, newValue);
            }} >{(i.label || i.text) as string}</RadioButton>
    </div>)}
    </div>;

}


