import { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { faImage } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface ImageOption {
    label: string;
    image_url: string;
}

interface ImageViewerModalProps {
    label: string;
    options: ImageOption[];
}

export function ImageViewerModal({ label, options = [] }: ImageViewerModalProps) {
    const [isOpen, setIsOpen] = useState(false);

    const toggleModal = () => setIsOpen(!isOpen);

    const hasImages = options.some((option) => option?.image_url);

    return (
        <>
            {hasImages && <div className="d-flex justify-content-between">
                <Button color="light" size="sm" className="ms-2 mb-1" onClick={toggleModal}>
                    <FontAwesomeIcon icon={faImage} />
                    <span className="ms-1">example</span>
                </Button>
            </div>}


            <Modal isOpen={isOpen} toggle={toggleModal} className="modal-lg">
                <ModalHeader toggle={toggleModal}>{label}</ModalHeader>
                <ModalBody>
                    <div className="d-flex flex-wrap align-items-center">
                        {options.filter((option) => option?.image_url).map((option, index) => (
                            <div key={index} className="m-2 text-center border p-2">
                                <img
                                    src={option.image_url}
                                    alt={option.label}
                                    className="file-preview-image"
                                    width="80px"
                                    height="80px" />
                                <p className="mt-2 fw-bold mb-0">{option.label}</p>
                            </div>
                        ))}
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="light" onClick={toggleModal}> Close </Button>
                </ModalFooter>
            </Modal>
        </>
    );
}
