import { useMsal } from "@azure/msal-react";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { getUser as getGeneralUser } from "../../../actions/Auth/actions";
import { ConfigProvider } from "../../../actions/Tenants/config/configHook";
import { scopes } from "../../../authConfig";
import { IS_DEMO } from "../../../constants/urls";
import { Loading } from "../../Helper/Loading";
import DefaultLayout from "../Layout/DefaultLayout";
import FullscreenLayout from "../Layout/FullscreenLayout";
import UserNotAuthorized from "./UserNotAuthorized";

interface RequireAuthPropsType {
    element: React.ReactNode;
    header?: React.ReactNode;
    fullscreen?: boolean;
    disableRefresh?: boolean;
    blueBackground?: boolean;
    getUser?: () => void;
}

export function RequireAuth({ element, fullscreen = false, disableRefresh = false, blueBackground = false, header, getUser = getGeneralUser }: RequireAuthPropsType) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [now, setNow] = useState(Date.now());
    const [authCheckComplete, setAuthCheckComplete] = useState(false); // Track whether auth check is done
    const { authenticated, isLoading } = useSelector((state: any) => state.auth);
    const { instance } = useMsal();
    const account = instance.getActiveAccount();
    const intervalRef = useRef<null | number>(null);

    const accessTokenRequest = {
        scopes,
        account,
        redirectUri: "/blank.html",
    };

    const redirectToLogin = () => navigate(`/login?redirect_to=${location.pathname}${location.search}`);

    useEffect(() => {
        // AUTH scheme is overwritten in demo
        if (IS_DEMO) {
            dispatch(getUser() as any);
            setAuthCheckComplete(true); // Mark as complete in demo mode
            return;
        }

        if (account) {
            // Attempt to refresh token
            instance.acquireTokenSilent(accessTokenRequest as any).then((response) => {
                if (response) {
                    dispatch(getUser() as any);
                }
                setAuthCheckComplete(true); // Auth check complete
            }).catch((error) => {
                console.error(error); // eslint-disable-line no-console
                redirectToLogin();
                setAuthCheckComplete(true); // Complete even on error
            });
        } else {
            redirectToLogin();
            setAuthCheckComplete(true); // Complete if no account
        }
    }, [account?.homeAccountId, instance, now, IS_DEMO]); // Prevent infinite loop by referring to homeAccountId

    useEffect(() => {
        // Set up periodic refresh unless disabled or in demo mode
        if (!IS_DEMO && !disableRefresh) {
            // TOOD: Refreshing of token is maybe not even needed
            intervalRef.current = setInterval(() => {
                setNow(Date.now());
            }, 2 * 60 * 1000) as unknown as number; // Refresh every 2 minutes
        }

        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
                intervalRef.current = null;
            }
        };
    }, [IS_DEMO, disableRefresh]);

    // Render child component or fallback based on authentication state
    const getChildComponent = () => {
        if (!authenticated && (!authCheckComplete || isLoading)) {
            // Show loading while auth check is in progress and we are not authenticated yet (so not for every refresh)
            return <Loading />;
        }
        if (authCheckComplete && ((!account && !IS_DEMO) || !authenticated)) {
            // Show UserNotAuthorized only after auth check is complete and is not loading
            return <UserNotAuthorized />;
        }
        return element;
    };

    // Choose layout template
    const getTemplate = (el) => {
        return fullscreen
            ? <FullscreenLayout>{el}</FullscreenLayout>
            : <DefaultLayout blueBackground={blueBackground} header={header}>{el}</DefaultLayout>;
    };

    return (
        <ConfigProvider refreshInterval={disableRefresh ? false : 6 * 60 * 1000}>
            {getTemplate(getChildComponent())}
        </ConfigProvider>
    );
}
