import { Button } from "reactstrap";
import { faImageSlash } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ImageOption } from "../../actions/Tenants/config/constantsTyped";

export interface ImageButtonProps {
    selected: boolean,
    onClick: () => void,
    disabled?: boolean,
    image_url?: string,
}

export function ImageButton({ selected, onClick, disabled, image_url }: ImageButtonProps) {
    return (

        <Button
            outline={!selected}
            disabled={disabled}
            className={"me-3 mt-1"}
            onClick={onClick}>
            {image_url ? (
                <img
                    src={image_url}
                    alt={`Img for value: ${selected}`}
                    style={{
                        display: "inline-block",
                        objectFit: "cover",
                        width: "50px",
                        height: "55px",
                    }}
                />
            ) : (
                <FontAwesomeIcon icon={faImageSlash} style={{ width: "50px", height: "55px" }} />
            )}
        </Button>);
}


export interface ImageRadioButtonsProps {
    options: ImageOption[],
    value: string,
    setValue: (value: number | undefined) => void,
}

export function ImageRadioButtons({
    options, value, setValue
}: ImageRadioButtonsProps) {
    return <div>
        {
            options.map(({ value: optionValue, disabled, image_url }, index) => <ImageButton
                key={index}
                selected={value === optionValue}
                disabled={disabled}
                image_url={image_url}
                onClick={() => {
                    const newValue: number | undefined = value === optionValue ? undefined : (optionValue as unknown) as number;
                    if (!newValue) (document.activeElement as any).blur();
                    setValue(newValue);
                }} />)
        }
    </div>;
}
