
export const defaultCheck = {
    test_id: null,
    pallet_id: null,
    growercode: null,
    avocados: [],
    is_valid: null,
    message: null,
    is_maxi_check: 0,
};

export const maxi_exits = [
    {
        text: "1",
        value: "1-2-hard",
    },
    {
        text: "2",
        value: "1-2-triggered",
    },
    {
        text: "3",
        value: "1-2-ready_to_eat",
    },
    {
        text: "4",
        value: "3-5-hard",
    },
    {
        text: "5",
        value: "3-5-triggered",
    },
    {
        text: "6",
        value: "3-5-ready_to_eat",
    },
    {
        text: "7",
        value: "skin-damage",
    },
    {
        text: "8",
        value: "B",
    },
];

export enum DEFECT_POSITION {
    EXTERNAL = "external",
    INTERNAL = "internal",
    LEGAL = "legal",
    COMMERCIAL = "commercial",
    COSMETIC_FRUIT = "cosmetic_fruit",
    COSMETIC_CROWN = "cosmetic_crown",
    FYTOSANITARY = "fytosanitary",

}

export enum DEFECT_SEVERITY {
    MINOR = "minor",
    MAJOR = "major",
    UNACCEPTABLE = "unacceptable",
    A = "A",
    B = "B",
    C = "C",
    D = "D",
}
