import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { chunk } from "../../../../../utils/chunk";
import PDFCheckChildrenDefects from "./PDFCheckChildrenDefects";
import PDFCheckTable from "./PDFCheckTable";
import { PDFPage } from "./PDFLayout";
import { tableStyles } from "./PDFTable";

export const styles = StyleSheet.create({
    metaContainer: {
        border: "1px solid #e3e3e6",
        borderRadius: "5px",
        padding: "10px",
        marginBottom: "10px",
        height: "auto",
        width: "70%",
    },
    container: {
        border: "1px solid #e3e3e6",
        borderRadius: "5px",
        padding: "10px",
        marginBottom: "10px",
        height: "auto",
        width: "40%",
    },
    horizontalMargins: {
        marginLeft: "5px",
        marginRight: "5px",
    },
    divider: {
        borderBottom: "1px solid #e3e3e6",
        paddingBottom: "4px",
        marginBottom: "4px"
    },
    cell: {
        width: "100%",
    },
    metaItems: {
        flexDirection: "row",
        display: "flex",
        justifyContent: "space-between",
        // alignItems: "top",
        color: "#939496",
        fontSize: "10px",
        marginBottom: "1px",
    },
    row: {
        flexDirection: "row",
        justifyContent: "space-between",
    },
    metaTextValueIntakeReport: {
        color: "#4f4f4f",
        maxWidth: "80%",
    },
    metaTextValueArtReport: {
        color: "#4f4f4f",
        fontWeight: "bold",
        marginBottom: "2px"
    },
});

export function PDFCheckFruitTables({
    header,
    rows,
    footer,
    title,
    children_note,
    layer_fields,
    children_layer_fields,
    checkIndex,
    check,
    layer,
    defect_groups,
    all_defects,
    logo_name,
    header_title,
}: any) {
    const headers = header.map((i, index) => <View key={index} style={[tableStyles.tableCell, tableStyles.tableHeader]} >
        <Text style={tableStyles.tableHeaderText}>{i.get_vector() || "\u00A0"}</Text>
    </View>);

    const allColumns = header.map((i, index) => [
        ...(rows.map((p) => <View style={[tableStyles.tableCellHeight, tableStyles.tableCell]} key={index + 1}>
            {p[index].get_vector() || "\u00A0"}
        </View>)),
        <View style={[tableStyles.tableCell, tableStyles.tableHeader]} key={index + 2}>
            <Text style={tableStyles.tableHeaderText}>{footer[index].get_vector() || "\u00A0"} </Text>
        </View>

    ]);

    // make chunks of all columns with 25 rows per column
    const chunked = header.map((_, index) => chunk(allColumns[index], 25));

    if (chunked.length === 0) {
        return null;
    }

    return chunked[0].map((_, chunkIndex) => <PDFPage footer key={chunkIndex} logo={logo_name} header_title={header_title}>
        <View>
            <Text style={tableStyles.pageTitle}>{title}</Text>
        </View>
        <View style={styles.row} wrap={false}>
            {/* CURRENT PALLET META USED IN CHEC REPORT */}
            {layer_fields && <View style={styles.metaContainer}>
                <View style={styles.cell}>
                    {layer_fields.filter((i) => !["label", "arrival_notes"].includes(i.fieldname_getter)).map((item, index) => (
                        <View key={index} style={styles.metaItems}>
                            <View>
                                <Text>{item.label}:</Text>
                            </View>
                            <View style={styles.metaTextValueIntakeReport}>
                                {item.display_vector("10px", 152, 150)}
                            </View>
                        </View>
                    ))}
                </View>
            </View>}
            {/* CHILDREN PALLET META */}
            {children_layer_fields && children_layer_fields.some((array) => array.length > 0) && <View style={styles.metaContainer}>
                <View style={styles.cell}>
                    {children_layer_fields[checkIndex].map((item, index) => (
                        <View key={index} style={styles.metaItems}>
                            <View>
                                <Text>{item.label}:</Text>
                            </View>
                            <View style={styles.metaTextValueIntakeReport}>
                                {item.display_vector("10px", 152, 150)}
                            </View>
                        </View>
                    ))}
                </View>
            </View>}
            {/* Defect frequency */}
            {defect_groups.map((group, index) => <PDFCheckChildrenDefects key={index} all_defects={all_defects} defect_group={group} check={check} layer={layer} />)}


        </View>

        <PDFCheckTable columns={chunked.map((i, colIndex) => [headers[colIndex], ...i[chunkIndex]])} />
        {children_note ? <Text style={tableStyles.note}>{children_note}</Text> : null}
    </PDFPage>);


}
